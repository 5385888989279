import React, { useState ,useCallback} from 'react'
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider,Tooltip, Grid, TextField, Typography, InputLabel, MenuItem, FormControl, Select, CircularProgress
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Transition } from '../../Constants/Constant';
import { AiFillDelete } from 'react-icons/ai'
import MarkdownEditor from "@uiw/react-markdown-editor";
import MarkdownPreview from '@uiw/react-markdown-preview';
import { MdOutlineCancel, MdProductionQuantityLimits } from 'react-icons/md';
import { useDropzone } from "react-dropzone"

const AddProduct = ({ getProductInfo, data }) => {
    // const [age, setAge] = useState('');

    // const handleChange = (event) => {
    //     setAge(event.target.value);
    // };
    const [open, setOpen] = useState(false);
    const [step , setStep]= useState({page:1,title:'input'});
    const [allFile,setAllFile]= useState([]);
    const [markdownVal, setMarkdownVal] = useState('');
    // const [loading,setLoadingin] = useState(false);
    let authToken = localStorage.getItem("Authorization")
    const [productInfo, setProductInfo] = useState({
        name: "",
        image: "",
        price: "",
        rating: "",
        category: "",
        type: "",
        description: "",
        author: "",
        brand: ""
    });
    // const [productInfo, setCredentials] = useState({ firstName: "", lastName: '', email: "", phoneNumber: '', password: "" })
    const handleOnchange = (e) => {
        setProductInfo({ ...productInfo, [e.target.name]: e.target.value })
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            if (!productInfo.name && !productInfo.price && !productInfo.rating && !productInfo.category && !productInfo.type && !markdownVal) {
                toast.error("Please Fill the all Fields", { autoClose: 500, theme: 'colored' })
                setLoading(false)
            }
            else if (productInfo.rating < 0 || productInfo.rating > 5) {
                toast.error("Please add valid rating", { autoClose: 500, theme: 'colored' })
                setLoading(false)
            }
            else {
                const { data } = await axios.post(`${process.env.REACT_APP_ADMIN_ADD_PRODUCT}`,
                    {
                        name: productInfo.name,
                        brand: productInfo.brand,
                        price: productInfo.price,
                        category: productInfo.category,
                        image: productInfo.image,
                        images: allFile,
                        rating: productInfo.rating,
                        type: productInfo.type,
                        author: productInfo.author,
                        description: markdownVal,
                    }, {
                    headers: {
                        'Authorization': authToken
                    }
                })
                setOpen(false);
                if (data === true) {
                    getProductInfo()
                    setLoading(false)
                    toast.success("Product added successfully", { autoClose: 500, theme: 'colored' })
                    setAllFile([])
                    setMyFiles([])
                    setStep({page:1,title:'upload'})
                    setMarkdownVal('')
                    setProductInfo({
                        name: "",
                        image: "",
                        price: "",
                        rating: "",
                        category: "",
                        type: "",
                        description: "",
                        author: "",
                        brand: "",
                        
                    });
                }
                else {
                        setLoading(false)
                    toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
                }
            }
        } catch (error) {
            setLoading(false)
            toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
        }

    }
    const productFilter = []

    if (productInfo.type === 'books') {
        productFilter.push('scifi', 'business', 'mystery', 'cookbooks', 'accessories')
    }
    else if (productInfo.type === 'robotics') {
        productFilter.push('Autonomous Mobile Robots', 'Automated Guided Vehicles','Articulated Robots','Humanoids','Cobots','Hybrids')
    }
    else if (productInfo.type === 'phones') {
        productFilter.push('Smart Phones', 'Camera Phones', 'Music Phones','3G Phones')
    }
    else if (productInfo.type === 'tablets') {
        productFilter.push('Apple iPad', 'Android Tablets')
    }
    else if (productInfo.type === 'computers') {
        productFilter.push('Hybrid Computer', 'Digital Computer', 'Analogue Computer')
    }
    else if (productInfo.type === 'electronic') {
        productFilter.push('Autonomous Mobile Robots', 'Automated Guided Vehicles','Articulated Robots','Humanoids','Cobots','Hybrids')
    }
    else {
        productFilter.push('all')

    }
    const typeDropdown = ['book', 'robotics', 'phone', 'tablet','electronic','computers'];
    const shoeBrand = ['adidas', 'hushpuppies', 'nike', 'reebok', 'vans']



    const [myFiles, setMyFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [buckets,setBuckets]= useState([])
    const [bucket,setBucket]= useState(null)
    const [image, setImage ] = useState("");
    const [ url, setUrl ] = useState("");
    
    

    const onDrop = useCallback(acceptedFiles => {
      setMyFiles([...myFiles, ...acceptedFiles])
    })
  
    const { getRootProps, getInputProps   , acceptedFiles,  fileRejections, } = useDropzone({
      onDrop,
      accept: {
        'image/jpeg': [],
        'image/png': [],
        'image/jpg': [],
      },
      maxFiles:4,
      maxSize:2000000,
    //   onDropRejected,
    })
  
    const removeFile = file => () => {
      const newFiles = [...myFiles]
      newFiles.splice(newFiles.indexOf(file), 1)
      setMyFiles(newFiles)
    }
  
    const removeAll = () => {
      setMyFiles([])
    }

   
     

    const files = myFiles.map(file => (
        <li key={file.path} style={{ marginBottom:'2%'}}>
          {file.path} - {file.size} bytes{" "}
          <a style={{ float:"right",}} onClick={removeFile(file)}>
                <Tooltip
                title='Remove From Cart'>
                <Button className='all-btn' sx={{ width: 7, borderRadius: '30px' }} variant='contained' color='error' >
                    <AiFillDelete style={{ fontSize: 15 }} />
                </Button>
            </Tooltip>
          </a>
        </li>
      ))

      
    const uploadFileAws=() => {
        // console.log('data-------------->',myFiles)
      
        var  arrayData = []
          setLoading(true)
        //   console.log('data-------------->',bucket)
          const uploaders = myFiles.map(image => {
            const formData = new FormData();
            console.log('done image---->',  image);
            formData.append('file', image);
            formData.append("upload_preset", "tutorial")
            formData.append("cloud_name","breellz")
            const options = {
                method: 'POST',
                headers: { 
                    'content-type': 'multipart/form-data',
                'access-control-allow-origin': '*',
              //   'Authorization': Auth.getToken(), 
                },
                  url:'https://api.cloudinary.com/v1_1/dghjdfdfz/image/upload',
              };
          // Make an AJAX upload request using Axio
            return  axios.post('https://api.cloudinary.com/v1_1/dghjdfdfz/image/upload', formData, options.headers)
                .then(response => {
                    console.log("response", response.data)
                 
                    const data = {
                        data:response.data.asset_id,
                        version_id:response.data.asset_id,
                        public:response.data.public_id,
                        url:response.data.url,
                        versionNum:response.data.version
                    }
                    arrayData.push(data)
                    // setAllFile([...allFile, data])
                    console.log('save data---------->', data)
                      if(response.data.status ===  "success"){
                          
                        // setLoading(false)
                        // setAllFile([...allFile, data])
                        // toast.success('File uploaded successfully');
                        // setStep({page:2,title:'input'})
                        // setMyFiles([])
                        }else{
                            
                          toast.success('Uploadeding');
                        }
                  
                      })
                    
                  });
          
            // Once all the files are uploaded 
              axios.all(uploaders).then(() => {
                  
            setStep({page:2,title:'input'})
               setLoading(false)
            setAllFile(arrayData)
            toast.success('Done uploading');
           }).catch(err =>   toast.error(err));
        }


    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', margin: "20px 0" }} >
                <Typography variant='h6' textAlign='center' color="#1976d2" fontWeight="bold">Add new product </Typography>
                <Button variant='contained' endIcon={<MdProductionQuantityLimits />} onClick={handleClickOpen}>Add</Button>
            </Box>
            <Divider sx={{ mb: 5 }} />
            <Dialog
                open={open}
                onClose={handleClose}
                keepMounted
                TransitionComponent={Transition}>
                <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', color: "#1976d2" }}> Add new product</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        <form onSubmit={handleSubmit}>

                       {
                           step.page === 1 ?
                           <div class="card has-table has-mobile-sort-spaced">
                                    <section className="containerDropzone">
                                    <div {...getRootProps({className: 'dropzone disabled'})}>
                                        <input {...getInputProps()} />
                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                    </div>
                                    <aside>
                                        <h4>Files</h4>
                                        <ul>{files}</ul>
                                    </aside>
                                    {
                                    step.page} length of image  {allFile.length}
                                   
                                    
                                    </section>
                                    {
                                        loading === true?
                                            <div style={{marginTop:'5%',alignItems: 'center',justifyContent: 'center',width:'20%',margin:'auto',}}>
                                                <p  style={{textAlign:'center'}} ><CircularProgress  /></p>
                                            </div>
                                          :
                                        <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                                            <Button fullWidth variant='contained' type='reset' color='error' onClick={handleClose} endIcon={<MdOutlineCancel />}>Cancel</Button>
                                            <Button  fullWidth variant="contained"  onClick={uploadFileAws} endIcon={<MdProductionQuantityLimits />}>Upload</Button>
                                        </DialogActions>
                                    }
                             </div>
                             : step.page === 2 ?
                             <Grid container spacing={2}>
                             <Grid item xs={12} >
                                 <TextField label="Name" name='name' value={productInfo.name} onChange={handleOnchange} variant="outlined" fullWidth />
                             </Grid>
                             <Grid item xs={12} sm={6} >
                                 <FormControl fullWidth>
                                     <InputLabel id="demo-simple-select-label">Product Type</InputLabel>
                                     <Select
                                         labelId="demo-simple-select-label"
                                         id="demo-simple-select"
                                         value={productInfo.type}
                                         label="Product Type"
                                         name='type'
                                         onChange={handleOnchange}
                                     >
                                         {typeDropdown.map(item =>
                                             <MenuItem value={item} key={item}>{item}</MenuItem>
                                         )}
                                     </Select>
                                 </FormControl>
                             </Grid>

                                <Grid item xs={12} sm={6} >
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Product Category</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={productInfo.category}
                                            label="Product Category"
                                            name='category'
                                            onChange={handleOnchange}
                                        >
                                            {productFilter.map(item =>
                                                <MenuItem value={item} key={item}>{item}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {
                                    productInfo.type === 'book' &&
                                    <Grid item xs={12} >
                                        <TextField label="Author" name='author' value={productInfo.author} onChange={handleOnchange} variant="outlined" required fullWidth />
                                    </Grid>
                                }
                                {
                                    productInfo.type === 'shoe' &&
                                    <Grid item xs={12} >
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Shoe Brand</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={productInfo.brand}
                                                label="Shoe Brand"
                                                name='brand'
                                                required
                                                onChange={handleOnchange}
                                            >
                                                {shoeBrand.map(item =>
                                                    <MenuItem value={item} key={item}>{item}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                             
                                <Grid item xs={12} sm={6}>
                                    <TextField label="Price" name='price' value={productInfo.price} onChange={handleOnchange} variant="outlined" inputMode='numeric' fullWidth />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField label="Rating" name='rating' value={productInfo.rating} onChange={handleOnchange} variant="outlined" inputMode='numeric' fullWidth />
                                </Grid>
                                <Grid item xs={12} sx={{ margin: "10px auto" }}>
                                <p>Description</p>
                                <MarkdownEditor
                                light="Light" 
                                maxHeight={300}
                                value={markdownVal}
                                onChange={(value) => {
                                  setMarkdownVal(value);
                                }}
                              />
                              </Grid>

                                {
                                    loading === true?
                                        <div style={{marginTop:'5%',alignItems: 'center',justifyContent: 'center',width:'20%',margin:'auto',}}>
                                             <p  style={{textAlign:'center'}} >  <CircularProgress  /> </p>
                                        </div>
                                      :
                                        <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                                            <Button fullWidth variant='contained' type='reset' color='error' onClick={handleClose} endIcon={<MdOutlineCancel />}>Cancel</Button>
                                            <Button type="submit" fullWidth variant="contained" endIcon={<MdProductionQuantityLimits />}>Add</Button>
                                        </DialogActions>
                                }
                            </Grid>
                            :
                            null
                        }
                            
                            
                        </form>
                    </Box >

                </DialogContent>
            </Dialog >
        </>
    )
}

export default AddProduct