import React, { useContext, useEffect, useState,useRef } from 'react'
import { Button, Container, Dialog, DialogActions, DialogContent, Grid, TextField,Checkbox, Typography,FormControlLabel } from '@mui/material'
import styles from './Chekout.module.css'
import { BsFillCartCheckFill } from 'react-icons/bs'
import { MdUpdate } from 'react-icons/md'
import axios from 'axios'
import { usePaystackPayment } from "react-paystack";
import { ContextFunction } from '../../Context/Context'
import { Link, useNavigate } from 'react-router-dom'
import { profile } from '../../Assets/Images/Image'
import { toast } from 'react-toastify'
import CopyRight from '../CopyRight/CopyRight'
import { Transition, handleClose } from '../../Constants/Constant'
import { AiFillCloseCircle, AiOutlineSave } from 'react-icons/ai'
import {IoIosSchool} from 'react-icons/io'

const CheckoutForm = () => {
    const { cart } = useContext(ContextFunction)
    const [userData, setUserData] = useState([])
    const [email,setEmail]= useState([])
    // const [shiping ,setShiping] = useState()
    const [openAlert, setOpenAlert] = useState(false);
    const [user, setUser]= useState(null);
    let authToken = localStorage.getItem('Authorization')
    let setProceed = authToken ? true : false
    let navigate = useNavigate()
    let totalAmount = sessionStorage.getItem('totalAmount')
    let where = sessionStorage.getItem('where')
    let shiping = sessionStorage.getItem('shiping')
    const [school,setSchool]= useState(null);
    const [antoine,setAntoine] =useState(false)
    const [schoolSelected,setSchoolSelected]= useState(null);
    const [value, setValue] = useState(1);

    const referenceNumber = () => {
        return "bld" + Math.floor(Math.random() * 1000000000 + 1);
      };
    
    
     
    useEffect(() => {
        if (setProceed) {
            getUserData()

        }
        else {
            navigate('/')
        }

    }, [])

    const [userDetails, setUserDetails] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        userEmail: '',
        address: '',
        zipCode: '',
        city: '',
        userState: '',

    })
    const getUserData = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_GET_USER_DETAILS}`, {
                headers: {
                    'Authorization': authToken
                }
            })
            // console.log('-------------------user',data)
            setUserData(data);
            setUser(data)
            setEmail(data.email)
            if (!data.address || !data.city || !data.zipCode || !data.userState) {
                setOpenAlert(true);
                console.log(1);
              }
             
            userDetails.firstName = data.firstName
            userDetails.lastName = data.lastName
            userDetails.userEmail = data.email
            userDetails.phoneNumber = data.phoneNumber
            userDetails.address = data.address
            userDetails.zipCode = data.zipCode
            userDetails.city = data.city
            userDetails.userState = data.userState
        } catch (error) {
            console.log(error);
        }

    }

    const config = {
        reference: referenceNumber(),
        email:email,
        amount: totalAmount+0+0,
        publicKey:process.env.REACT_APP_PAYSTACTPAYEMT_LIVE_FRONT
      };
    
    
      const initializePayment = usePaystackPayment(config);
      const Ref = useRef(null);


    const checkOutHandler = async (e) => {
        e.preventDefault()
        
        if (!userDetails.firstName || !userDetails.lastName || !userDetails.userEmail || !userDetails.phoneNumber || !userDetails.address || !userDetails.zipCode || !userDetails.city || !userDetails.userState) {
            toast.error("Please fill all fields", { autoClose: 500, theme: "colored" })
        }
        else {
            // console.log('amount data------------->', process.env.REACT_APP_PAYSTACTPAYEMT_TEXTING_FRONT )
            initializePayment(onSuccess, onClose)
     
        }
    }


    const schoolData = [
        {  id:1, label: "Sleek angels international schools", address: "30 Rumuevuorlu Rd., Off Miniorlu by Ada George Rd., PortHarcourt, Rivers State" ,zip:500001,state:'Port Harcourt', city:'Rivers State'},
  
 ];
    const handleOnchange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
    }


    const onSuccess = (reference) => {
       
        // console.log('--------------------->plan',reference,shiping,where)
        const CreateData = {
          reference: reference.reference,
          user:user._id,
          productDetails: JSON.stringify(cart),
           userId: userData._id,
           userDetails: JSON.stringify(userDetails),
            student:true,
            shiping,
            school:school === null ? null : school.label,
            where,
           amount: totalAmount,
           currency: "NGN",
            name: userData.firstName + ' ' + userData.lastName,
            description: "Payment",
                image: profile,
            prefill: {
                name: userData.firstName + ' ' + userData.lastName,
                email: userData.email,
                contact: userData.phoneNumber
            },
        }
        // console.log('--------------------->plan',CreateData)
        if(reference.status === 'success'){
       const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(CreateData),
             };
                fetch(`${process.env.REACT_APP_PAYEMENTVERIFICATION}`, requestOptions)
                       .then(res => res.json())
                       .then((res)=>{
                        if(res.data.status === true){
                            // console.log('---------------------->',res.data.status )
                            timePush()
                         }
                        
               }).catch( (error) => {
                        
          })
        }
      };



 const handlePickSchool = (data)=>{
    //  console.log('pick school-------------->',data)
      setSchoolSelected(data)
      let result = schoolData.find(o => o.id === parseFloat(data));
       setSchool(result)
       userDetails.address = result.address;
       userDetails.zipCode = result.zip;
       userDetails.city = result.city;
       userDetails.userState = result.state;
      //  console.log('pick school-------------->',result,'userDetails-------------------->',userDetails)
   }


      const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        console.log("closed");
      };
    


const timePush =(data)=>{
    setTimeout(function(){
  
        navigate('/')
   }, 1500);
  }


    return (
        <>
            <Container sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: 10 }}>
                <Typography variant='h6' sx={{ margin: '20px 0' }}>Checkout</Typography>
                <form noValidate autoComplete="off" className={styles.checkout_form} onSubmit={checkOutHandler} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField inputProps={{ readOnly: true }} disabled label="First Name" name='firstName' value={userDetails.firstName || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField inputProps={{ readOnly: true }} disabled label="Last Name" name='lastName' value={userDetails.lastName || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField inputProps={{ readOnly: true }} disabled label="Contact Number" type='tel' name='phoneNumber' value={userDetails.phoneNumber || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField inputProps={{ readOnly: true }} disabled label="Email" name='userEmail' value={userDetails.userEmail || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                        </Grid>
                        <Grid  style={{width:'100%'}}>
                        <FormControlLabel
                            control={
                              <Checkbox checked={antoine} onChange={()=>setAntoine(!antoine)} name="antoine" 
                              sx={{ '& .MuiSvgIcon-root': { fontSize: 30} }}
                              />
                            }
                            label="Student"
                         />
                         </Grid>
                         <br/>
                        {
                         antoine ?
                            <Grid  style={{width:'100%',}}>
                              {schoolData.map((item) => {
                                return (
                                          <a onClick={()=>handlePickSchool(item.id)}  style={{width:'100%'}}>
                                        
                                            <div className={item.id === parseFloat(schoolSelected) ?'publiceCollection-school':'publiceCollection-school-null'} >
                                                  <Checkbox
                                                  key={item.label}
                                                  // style={{float:'right'}}
                                                  // onChange={handleChange}
                                                  sx={{ '& .MuiSvgIcon-root': { fontSize: 30} }}
                                                  checked={item.id === parseFloat(schoolSelected)}
                                                  value={item.id}
                                              >
                                                  {item.value}
                                                  
                                              </Checkbox>

                                              <h3>
                                              <IoIosSchool />
                                              </h3>
                                                
                                            
                                              <p>   {item.label}</p>
                                             
                                              
                                              
                                              
                                            </div>
                                          </a>
                                      )
                                  })}
                             </Grid>
                            :
                             <>
                                <Grid item xs={12}>
                                    <TextField label="Address" name='address' value={userDetails.address || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField label="City" name='city' value={userDetails.city || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField type='tel' label="Postal/Zip Code" name='zipCode' value={userDetails.zipCode || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField label="Province/State" name='userState' value={userDetails.userState || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                                </Grid>
                            </>
                         }    
                    </Grid>
                    <Container sx={{ display: 'flex', gap: 10, justifyContent: 'center', marginTop: 5 }}>
                        <Link to='/update'> <Button variant='contained' endIcon={<MdUpdate />}>Update</Button></Link>
                        <Button variant='contained' endIcon={<BsFillCartCheckFill />} type='submit' >Checkout</Button>
                    </Container>
                </form >

                <Dialog
                    open={openAlert}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => handleClose(setOpenAlert)}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 }, display: 'flex', justifyContent: 'center' }}>
                        <Typography variant='h6'>Add permanent address then you don't have to add again.  </Typography>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Link to='/update'> <Button variant='contained' endIcon={<AiOutlineSave />} color='primary' >Add</Button></Link>
                        <Button variant='contained' color='error' endIcon={<AiFillCloseCircle />} onClick={() => handleClose(setOpenAlert)}>Close</Button>
                    </DialogActions>
                </Dialog>


            </Container >
            <CopyRight sx={{ mt: 8, mb: 10 }} />

        </>
    )
}

export default CheckoutForm