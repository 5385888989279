import React, { useContext, useEffect, useState } from 'react'
import { ContextFunction } from '../../Context/Context';
import {
    Button,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    Container,
    CssBaseline,
    Box,
} from '@mui/material'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AiFillCloseCircle, AiOutlineLogin } from 'react-icons/ai'
import UpdateCartCard from '../../Components/Card/CartCard/UpdateCartCard';
import ProductCard from '../../Components/Card/Product Card/ProductCard';
import './Cart.css'
import OrderSummary from './OrderSummary';
import { EmptyCart } from '../../Assets/Images/Image';
import { Transition } from '../../Constants/Constant';
import CopyRight from '../../Components/CopyRight/CopyRight';



const Cart = () => {
    const { cart, setCart } = useContext(ContextFunction)
    const [total, setTotal] = useState(0)
    const [openAlert, setOpenAlert] = useState(false);
    const [previousOrder, setPreviousOrder] = useState([]);
    const [shippingCoast, setShippingCoas] = useState(null);
    const [shipData, setShepData] = useState(null);


    const navigate = useNavigate()
    let authToken = localStorage.getItem('Authorization')
    let setProceed = authToken ? true : false


    useEffect(() => {
        if (setProceed) {
            getCart()
            getPreviousOrder()

        }
        else {
            setOpenAlert(true)
        }
        window.scroll(0, 0)

    }, [])

    useEffect(() => {
        if (setProceed) {
            setTotal(cart.reduce((acc, curr) => (acc + ((curr.productId?.price * curr.quantity) )), 0))
        }

    }, [cart])

    const getCart = async () => {
        if (setProceed) {
            const { data } = await axios.get(`${process.env.REACT_APP_GET_CART}`,
                {
                    headers: {
                        'Authorization': authToken
                    }
                })
            setCart(data);
        }

    }
    const handleClose = () => {
        setOpenAlert(false);
        navigate('/')
    };
    const handleToLogin = () => {
        navigate('/login')
    };
    const getPreviousOrder = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_GET_PREVIOUS_ORDER}`,
            {
                headers: {
                    'Authorization': authToken
                }
            })
        setPreviousOrder(data)
        // console.log('--------------setPreviousOrder---4---------data',data)
    }

    const removeFromCart = async (product) => {
        if (setProceed) {
            try {
                const response = await axios.delete(`${process.env.REACT_APP_DELETE_CART}/${product._id}`, {
                    headers: {
                        'Authorization': authToken
                    }
                })
                toast.success("Removed From Cart", { autoClose: 500, theme: 'colored' })
                setCart(cart.filter(c => c.productId._id !== product.productId._id))
            } catch (error) {
                toast.error("Something went wrong", { autoClose: 500, theme: 'colored' })

            }
        }
    }
    const proceedToCheckout = async () => {
        if (cart.length <= 0) {
            toast.error("Please add items in cart to proceed", { autoClose: 500, theme: 'colored' })
        }
        else {
            sessionStorage.setItem('totalAmount', total)
            sessionStorage.setItem('where', shipData)
            sessionStorage.setItem('shiping',  shippingCoast)
            navigate('/checkout')
        }
    }

    const passData = (data)=>{
        // console.log("passData---------->",data)
        // shippingCoast = parseFloat(data)
        setShippingCoas(parseFloat(data.amount))
        setShepData(data.label)
        setTotal(cart.reduce((acc, curr) => (acc + ((curr.productId?.price * curr.quantity) +  parseFloat(data.amount))), 0))
    
    }

    const  moneyFormat =(price, sign = 'N') =>{
        const pieces = parseFloat(price).toFixed(2).split('')
        let ii = pieces.length - 3
        while ((ii-=3) > 0) {
          pieces.splice(ii, 0, ',')
        }
        return sign + pieces.join('')
      }


    function ToSeoUrl(url) {
        
        // make the url lowercase         
        var encodedUrl = url.toString().toLowerCase(); 
      
        // replace & with and           
        encodedUrl = encodedUrl.split(/\&+/).join("-and-")
      
        // remove invalid characters 
        encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");       
      
        // remove duplicates 
        encodedUrl = encodedUrl.split(/-+/).join("-");
      
        // trim leading & trailing characters 
        encodedUrl = encodedUrl.trim('-'); 
      
        return encodedUrl; 
      }
      
    
    return (
        <>
            <CssBaseline />
            
               <div className='cart-body'> 
                    <div className='cart-product'>
                            <div className='cart-title'>
                               <Typography variant='h6' sx={{ textAlign: 'left', marginTop: 2, color: '#1976d2', fontWeight: 'bold' }}>Cart { cart.length}</Typography>
                            </div>
                            {
                                setProceed &&
                                cart.length <= 0 &&
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="main-card">
                                        <img src={EmptyCart} alt="Empty_cart" className="empty-cart-img" />
                                        <Typography variant='h6' sx={{ textAlign: 'center', color: '#1976d2', fontWeight: 'bold' }}>Your Cart is Empty</Typography>
                                    </div>
                                </Box>
            
                            }
                            <Container sx={{ display: 'flex', flexDirection: "column", mb: 10 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                                    {
                                        cart.length > 0 &&
                                        cart.map(product =>
                                            <UpdateCartCard product={product} removeFromCart={removeFromCart} key={product._id} />

                                        )}
                                </Box>

                            

                            </Container>
                    </div>
                    <div className='cart-process'>
                    {
                        cart.length > 0 &&
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <OrderSummary proceedToCheckout={proceedToCheckout} passData={passData} total={total} shippingCoast={shippingCoast} />
                        </Box>
                    }
                    </div>
               </div>

              
                
       
        
            <Dialog
                open={openAlert}
                keepMounted
                onClose={handleClose}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 }, display: 'flex', justifyContent: 'center' }}>
                    <Typography variant='h5'> Please Login To Proceed</Typography>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <Button variant='contained' onClick={handleToLogin} endIcon={<AiOutlineLogin />} color='primary'>Login</Button>
                    <Button variant='contained' color='error' endIcon={<AiFillCloseCircle />} onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <CopyRight sx={{ mt: 8, mb: 10 }} />
        </>
    )
}

export default Cart