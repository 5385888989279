import React, { useEffect ,useState} from 'react'
import axios from 'axios'
import { Container, Typography ,Grid,CircularProgress, Select} from '@mui/material'
import { AiOutlineHeart, AiOutlineShoppingCart, AiFillCloseCircle,AiOutlineClose } from 'react-icons/ai'
import { BsSearch} from 'react-icons/bs'
import {MdOutlineAdd,MdDeleteOutline } from 'react-icons/md'
import { Link, useParams,useNavigate } from 'react-router-dom'
import { Box } from '@mui/system'
import { AiOutlineSearch,AiOutlineLogin } from 'react-icons/ai';
import { Button, Dialog, DialogActions, DialogContent, ButtonGroup,TextField,InputAdornment,IconButton, InputLabel,TableCell,TableRow,
    TableHead ,Table,TableBody
} from '@mui/material';
import { useContext } from 'react'
import { ContextFunction } from '../../Context/Context'

import CopyRight from '../../Components/CopyRight/CopyRight'
import Slider from "react-slick";
import {BiDownArrow ,BiSolidDownArrow} from 'react-icons/bi'
import { AiFillDelete} from 'react-icons/ai'
import { getCart, getWishList, handleLogOut, handleClickOpen, Transition } from '../../Constants/Constant'
import { toast } from 'react-toastify';
import './Order.css'
// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";
const  Order = (props) => {
    const { cart, setCart } = useContext(ContextFunction)
    const [sectionsData, setSectionsData] = useState([])
    const [loading,setLoading] = useState(false);
    const [openModal,setOpenModal] = useState(false);
    const [section,setSection] = useState(null);
    const [loadingData,setLoadingData] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [previousOrder, setPreviousOrder] = useState([]);
    const [login,setLogin] = useState(false);
    const [closeSearch,setCloseSearch]= useState(false);
    let navigate = useNavigate()
    let authToken = localStorage.getItem('Authorization')
    let setProceed = authToken ? true : false

    useEffect(() => {
      setLoading(true)
      getPreviousOrder()
        // getCart()
        window.scroll(0, 0)
    }, [])

    const getPreviousOrder = async () => {
        if (setProceed) {
        const { data } = await axios.get(`${process.env.REACT_APP_GET_PREVIOUS_ORDER}`,
            {
                headers: {
                    'Authorization': authToken
                }
            })
        setPreviousOrder(data)
        // console.log('--------------setPreviousOrder---4---------data',data)
        }else{
            setLogin(true)
        }
    }


    const handleClose = () => {
        setOpenAlert(false);
        // setShareMoald(false)
        // setOpenEdit(false)
    };
     
    const handleToLogin = () => {
        navigate('/login')
    };

    function ToSeoUrl(url) {
        
        // make the url lowercase         
        var encodedUrl = url.toString().toLowerCase(); 
      
        // replace & with and           
        encodedUrl = encodedUrl.split(/\&+/).join("-and-")
      
        // remove invalid characters 
        encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");       
      
        // remove duplicates 
        encodedUrl = encodedUrl.split(/-+/).join("-");
      
        // trim leading & trailing characters 
        encodedUrl = encodedUrl.trim('-'); 
      
        return encodedUrl; 
      }
      

    // const removeBook =sectionBooks.filter(item => item._id !== bookStrodInSection.data._id)

    return (
             <>
    
             <div className='homepage-c3768_2zwQl'>
                    <h5 className='_069ac_1c5-v'>    {setProceed && previousOrder.length > 0 && <p>Previous Orders</p>}</h5> 
                </div>
                 <div className='orderBody-sub'>
                    <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" sx={{ color: "#1976d2", fontWeight: 'bold' }}>Product Name</TableCell>
                                            <TableCell align="left" sx={{ color: "#1976d2", fontWeight: 'bold' }}>Image</TableCell>
                                            <TableCell align="left" sx={{ color: "#1976d2", fontWeight: 'bold' }}>Price</TableCell>
                                            <TableCell align="left" sx={{ color: "#1976d2", fontWeight: 'bold' }}>Quantity</TableCell>
                                            <TableCell align="left" sx={{ color: "#1976d2", fontWeight: 'bold' }}>Rating</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                    
                                        previousOrder.map(pro => (
                                            
                                            pro.productData.map(product => 
                                              
                                            <TableRow key={product._id}>
                                            {
                                                product.name === undefined ?
                                                <TableCell align="left" >
                                                    <Link to={`/Detail/type/${product.type}/${product.productId._id}`}>
                                                        {product.productId.name}
                                                    </Link>
                                                </TableCell>

                                                :
                                                <TableCell align="left" >
                                                    <Link to={`/Detail/type/${product.type}/${ToSeoUrl(product.name)}/${product._id}`}>
                                                        {product.name}
                                                    </Link>
                                                </TableCell>

                                            }
                                            {
                                                product.images === undefined ?
                                                <TableCell align="left">
                                                    <Link to={`/Detail/type/${product.type}/${product.productId._id}`}>
                                                        <img src={product.productId.images[0].url} alt={product.productId.name} style={{ width: "50px", height: "50px", objectFit: "contain" }} />
                                                    </Link>
                                                </TableCell>
                                                :
                                                <TableCell align="left">
                                                    <Link to={`/Detail/type/${product.type}/${product._id}`}>
                                                        <img src={product.images[0].url} alt={product.name} style={{ width: "50px", height: "50px", objectFit: "contain" }} />
                                                    </Link>
                                                </TableCell>
                                            }
                                            {
                                                product.price === undefined ?
                                                    <TableCell align="left" >
                                                        <Link to={`/Detail/type/${product.type}/${product.productId._id}`}>
                                                            {product.productId.price}
                                                        </Link>
                                                    </TableCell>
                                                    :
                                                    <TableCell align="left" >
                                                        <Link to={`/Detail/type/${product.type}/${ToSeoUrl(product.name)}/${product._id}`}>
                                                            {product.price}
                                                        </Link>
                                                    </TableCell>
                                            }
                                            {
                                                product.quantity === undefined ?
                                                <TableCell align="left" >
                                                    <Link to={`/Detail/type/${product.type}/${ToSeoUrl(product.name)}/${product.productId._id}`}>
                                                        {product.quantity}
                                                    </Link>
                                                </TableCell>
                                                :
                                                <TableCell align="left" >
                                                    <Link to={`/Detail/type/${product.type}/${product._id}`}>
                                                        {product.quantity}
                                                    </Link>
                                                </TableCell>
                                            }
                                            {
                                                product.rating === undefined ?
                                                <TableCell align="left" >
                                                   
                                                        {product.productId.rating}
                                                  
                                                </TableCell>
                                                :
                                                <TableCell align="left" >
                                                    <Link to={`/Detail/type/${product.type}/${ToSeoUrl(product.name)}/${product._id}`}>
                                                        {product.rating}
                                                    </Link>
                                                </TableCell>
                                            }

                                            </TableRow>
                                            )
                                     )
                                    )}
                                    </TableBody>
                        </Table>
                    </div>
                                
                          
                    <Dialog open={login}
                    keepMounted
                    onClose={handleClose}
                    TransitionComponent={Transition}
        
                    aria-describedby="alert-dialog-slide-description">
                    <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 }, display: 'flex', justifyContent: 'center' }}>
                        <Typography variant='h5'> Please Login To Proceed</Typography>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Button variant='contained' onClick={handleToLogin} endIcon={<AiOutlineLogin />} color='primary'>Login</Button>
                        <Button variant='contained' color='error' endIcon={<AiFillCloseCircle />} onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
                  
            <CopyRight sx={{ mt: 8, mb: 10 }} />
           </ >
      )
}

export default Order