import React, { useEffect ,useState} from 'react'
import axios from 'axios'
import { Container, Typography ,Grid,CircularProgress,TextField,CardActionArea,CardActions} from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { AiOutlineHeart, AiOutlineShoppingCart, AiFillCloseCircle,AiOutlineClose } from 'react-icons/ai'
import { Box } from '@mui/system'
import { useContext } from 'react'
import { ContextFunction } from '../../Context/Context'
import CopyRight from '../../Components/CopyRight/CopyRight'
import Slider from "react-slick";

import TimeAgo from 'react-timeago'
import './example.css'
// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";
const Example = () => {
    const { setCart } = useContext(ContextFunction)
    const [productData, setProductData] = useState([])
    const [loading,setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [productOld, setProductOld] = useState([])
    const [collections,setCollections] = useState([])
    const [sections,setSections]= useState([])
    const [closeSearch,setCloseSearch]= useState(false);
    const [show, setShow] = useState(false)

    

    return (
        <>  
            <div className='HomePage-search-nav'>
           
                  
                    <div class="content">
                        
                        <nav role="navigation">
                        <div id="menuToggle">
                            <input type="checkbox" />
                            <span></span>
                            <span></span>
                            <span></span>
                      
                        </div>
                        </nav>
                    </div>
                 
                
             </div>
            <CopyRight sx={{ mt: 8, mb: 10 }} />
        </ >
    )
}

export default Example