export let payment = 'https://res.cloudinary.com/dxguqzge7/image/upload/v1682838910/payment_mkel3w.jpg';
export let profile = 'https://res.cloudinary.com/dxguqzge7/image/upload/v1682838912/vecteezy_user-avatar-line-style__x94mjc.jpg';
export let Computers = 'https://i.ebayimg.com/images/g/H5cAAOSw-lxk6Gyr/s-l400.webp';
export let Phone = 'https://i.ebayimg.com/images/g/yJYAAOSwNohk6Gyc/s-l400.webp';
export let Tablet = 'https://kusnap.com/static/category/mobile-phones-and-tablets.webp';
export let Robotics = 'https://www.deviceplus.com/wp-content/uploads/2020/11/arduino-robot-kits.jpg';
export let Electronics = 'https://i.ytimg.com/vi/DF1WGZyAnoE/maxresdefault.jpg';
export let laboratory = 'https://images.drlogy.com/assets/uploads/img/general/drlogy-app/Pathology%20Lab%20Software%20-%20Drlogy.webp';
export let SchoolsAccessories = 'https://i0.wp.com/helpinghandshawaii.org/wp-content/uploads/2021KitContents-1.jpg?w=1420&ssl=1';
export let Books = 'https://res.cloudinary.com/dxguqzge7/image/upload/v1682838761/Book_lc6ikb.jpg';
export let EmptyCart = 'https://res.cloudinary.com/dxguqzge7/image/upload/v1682838909/Cart_bk4xgl.jpg';
export let customerReview = "https://res.cloudinary.com/dxguqzge7/image/upload/v1682838911/Review_bm6chw.jpg";


