import React, { useEffect ,useState,useRef} from 'react'
import axios from 'axios'
import {
   Button, Dialog, DialogActions, DialogContent, DialogTitle,Skeleton,Container,Avatar,  Rating,Checkbox, Divider,Tooltip,
    Grid, TextField, Typography, InputLabel, MenuItem, FormControl, Select, CircularProgress,FormControlLabel
} from '@mui/material';
import { Link, useParams,useNavigate } from 'react-router-dom'
import { Box } from '@mui/system'
import { useContext } from 'react'
import { ContextFunction } from '../../Context/Context'
import { getCart, getWishList, handleLogOut, handleClickOpen, Transition } from '../../Constants/Constant'
import { AiFillHeart, AiFillCloseCircle, AiOutlineSave,AiOutlineLogin, AiOutlineShareAlt ,AiFillDelete} from 'react-icons/ai'
import { MdAddShoppingCart,MdOutlineAdd ,MdOutlineCancel,MdProductionQuantityLimits,MdEditDocument} from 'react-icons/md'
import Slider from "react-slick";
import {BiDownArrow ,BiSolidDownArrow} from 'react-icons/bi'
import {IoIosSchool} from 'react-icons/io'
import { Helmet } from "react-helmet";
import CollectionReview from '../../Components/Review/CollectionReview';
import { BsFillCartCheckFill } from 'react-icons/bs'
// import { IoBagCheckOutline } from 'react-icons/io5'
import MarkdownPreview from '@uiw/react-markdown-preview';
import { MdUpdate } from 'react-icons/md'
import { profile } from '../../Assets/Images/Image'
import { usePaystackPayment } from "react-paystack";
import { BsImage } from 'react-icons/bs'
import { toast } from 'react-toastify';
import ImageGallery from 'react-image-gallery';
// import ProductCard from './ProductCollectionCard'
import './collectionPublic.css'
import {
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  EmailShareButton,
  EmailIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon
  
} from 'react-share';
const  CollectionPublish = (props) => {
  const { cart } = useContext(ContextFunction)
  const [userData, setUserData] = useState([])
  const [email,setEmail]= useState('')
    const { setCart } = useContext(ContextFunction)
    const { id, cat } = useParams()
    const [productData, setProductData] = useState([])
    const [loading,setLoading] = useState(false);
    // const [section,setSection] = useState(null);
    const [section , setSection] = useState(null)
    const [openAlert, setOpenAlert] = useState(false);
    const [loginOpen,setLoginOpen]= useState(false);
    const [sectionData,setSectionData]= useState(null)
    const [images, setImages]= useState([])
    const [showData,setShowData]=useState(null)
    const [totalProduct,setTotalProduct] = useState(0)
    const [products,setProducts] = useState([])
    const navigate = useNavigate()
    const [getData,setGetData] = useState(null);
    const [openEdit, setOpenEdit] = useState(false);
    const [loadingData,setLoadingData] = useState(false);
    const [shareMoald,setShareMoald] = useState(false);
    const [allPrice,setAllPrice]= useState(false);
    const [user, setUser]= useState(null);
    let authToken = localStorage.getItem('Authorization')
    let setProceed = authToken ? true : false
    const [step,setStep]= useState({page:1,title:'collection'})
    let totalAmount = sessionStorage.getItem('totalAmount')
    let where = sessionStorage.getItem('where')
    let shiping = sessionStorage.getItem('shiping')
    const [shipData, setShipData] = useState(null);
    const [showProductData,setShowProductData] = useState(false)
    const [school,setSchool]= useState(null);
    const [schoolSelected,setSchoolSelected]= useState(null);
    const [antoine,setAntoine] =useState(false)
    const [value, setValue] = useState(1);
    const shareUrl = 'https://www.store.ufuon.com/project/detail';
    const referenceNumber = () => {
        return "bld" + Math.floor(Math.random() * 1000000000 + 1);
      };
    
    const [userDetails, setUserDetails] = useState({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      userEmail: '',
      address: '',
      zipCode: '',
      city: '',
      userState: '',

  })
    const [productInfo, setProductInfo] = useState({
      name: "",
      description:''
       });
  
    useEffect(() => {
      setLoading(true)
      getAllSection()
      getUserData()
        window.scroll(0, 0)
    }, [])


    const getUserData = async () => {
      try {
        if (authToken !== null) {
          const { data } = await axios.get(`${process.env.REACT_APP_GET_USER_DETAILS}`, {
              headers: {
                  'Authorization': authToken
              }
          })
       
          setUserData(data);
          setUser(data)
          setEmail(data.email)
          if (!data.address || !data.city || !data.zipCode || !data.userState) {
              setOpenAlert(true);
              // console.log(1);
            }
           
          userDetails.firstName = data.firstName
          userDetails.lastName = data.lastName
          userDetails.userEmail = data.email
          userDetails.phoneNumber = data.phoneNumber
          userDetails.address = data.address
          userDetails.zipCode = data.zipCode
          userDetails.city = data.city
          userDetails.userState = data.userState
        }
      } catch (error) {
          // console.log(error);
      }

  }

  
  const config = {
      
      reference: referenceNumber(),
      email:email,
      amount:parseFloat(allPrice+'00'),
      publicKey:process.env.REACT_APP_PAYSTACTPAYEMT_LIVE_FRONT
   };
  
  
    const initializePayment = usePaystackPayment(config);
    const Ref = useRef(null);

    const getAllSection = async () => {
      const ArrayDATA = []
      // console.log('process.env.REACT_APP_GET_CART',process.env.REACT_APP_GET_CART)

          const { data } = await axios.get(`${process.env.REACT_APP_SECTIONS}/${id}`,
              {
                  headers: {
                      'Authorization': authToken
                  }
              })
          // console.log('-------SECTIONS------------user',data)
        setSectionData(data.data);
        CountTotal(formattedData(data.data.products, data.data.quantity))
        setSection(data.data.products[0])
        setProducts(formattedData(data.data.products, data.data.quantity))
        var images = data.data.products[0]
                for (let i = 0; i < images.images.length; i++) {
                  // console.log('data----------images------->',images.images[i].url)
                      const newData ={
                              original:  images.images[i].url,
                              thumbnail:  images.images[i].url,
                      }
                      //  setImages([...images, newData])
                ArrayDATA.push(newData)
          }
          setImages(ArrayDATA)
          setLoading(false)
    

    }

  const handleClose = () => {
    setOpenAlert(false);
    setShareMoald(false)
    setLoginOpen(false)
    // setOpenEdit(false)
};



const checkOutHandler = async (e) => {
  e.preventDefault()
  
  if(antoine === true){
     if(school === null){
      toast.error("Click to Select school", { autoClose: 500, theme: "colored" })
      return
     }
  }
  if (!userDetails.firstName || !userDetails.lastName || !userDetails.userEmail || !userDetails.phoneNumber || !userDetails.address || !userDetails.zipCode || !userDetails.city || !userDetails.userState) {
      toast.error("Please fill all fields", { autoClose: 500, theme: "colored" })
  }
  else {
      // console.log('amount data--------lp----->',parseFloat(allPrice+'00'))
      initializePayment(onSuccess, onClose)

  }
}

const handleOnchange = (e) => {
  setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
}
 
 
   
   const clickModal =()=>{
    setOpenAlert(true)
   }

     // const [productInfo, setCredentials] = useState({ firstName: "", lastName: '', email: "", phoneNumber: '', password: "" })
 

   const SendDataSelect = (data) =>{
    props. pickSelection(data)
    // props.SendDataToCallection(data)
   }
   
   const SendDataToCallection = (data) =>{

   }

   const ClickToShow =(data)=>{
    setShowData(data)
    const ArrayDATA = []
    var images =  data.images
    for (let i = 0; i < data.images.length; i++) {
      // console.log('data----------images------->', data.images[i].url)
                const newData ={
                        original:  data.images[i].url,
                        thumbnail:  data.images[i].url,
                }
                //  setImages([...images, newData])
          ArrayDATA.push(newData)
      }
      setImages(ArrayDATA)
      setShowProductData(true)
   }


   const CountTotal = (data)=> {
    // const  quantity = 1
      var totaltAll = data.reduce(function(res,item) {
        return res + (item.price * item.quantity);
      }, 0);
      setTotalProduct(totaltAll)
      // console.log(totaltAll);

  }

  // const getProduct = (data) =>{
  //   // console.log('data product--------->',data)
  //   navigate('/list')
  // }

  const shareProduct = (data) =>{ 
    // console.log('--------data--------->',data)
    setShareMoald(true)
  }

  const removeProductData = async(data) =>{
    setLoadingData(true)
    var removeBook = products.filter(item => item._id !== data._id)
    setProducts(removeBook)
    CountTotal(removeBook)
    const ArrayDATA = []
    // var images =  data.images
    var product = removeBook[0]
      if(removeBook.length === 0){
         }else{
        for (let i = 0; i < product.images.length; i++) {
          // console.log('data----------images------->', product.images[i].url)
                    const newData ={
                            original:  product.images[i].url,
                            thumbnail:  product.images[i].url,
                    }
                    //  setImages([...images, newData])
              ArrayDATA.push(newData)
            }
        }
   
      if(removeBook.length === 0){
        setImages([])
          }else{
          setImages(ArrayDATA)
      }
      
        try {
         const { data } = await axios.put(`${process.env.REACT_APP_SECTIONS}/${sectionData._id}`,
                  {
                    products: removeBook
                  }, {
                  headers: {
                      'Authorization': authToken
                  }
              })
              setOpenAlert(false);
              if (data.status === true) {
                toast.success("Collection Updated", { autoClose: 500, theme: 'colored' })
                  // setProductInfo({
                  //     name: "",
                      
                  //    });
                  setSectionData(data.data)
                 setLoadingData(false)
                 setOpenEdit(false)
              }
              else {
                setLoadingData(false)
                  toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
              }
         } catch (error) {
           setLoadingData(false)
          toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
      }
  }


   const  moneyFormat =(price, sign = 'N') =>{
    const pieces = parseFloat(price).toFixed(2).split('')
    let ii = pieces.length - 3
    while ((ii-=3) > 0) {
      pieces.splice(ii, 0, ',')
    }
    return sign + pieces.join('')
  }

 const EditCollection = () =>{
    setOpenAlert(true)
    productInfo.name = sectionData.name
    productInfo.description =  sectionData.description
 }
  

 const formattedData = (data, array) => data.map((obj, i) => (  
  {
    ...obj,
    quantity: array[i]
  }
));
 

  const UpdateSubmit = async (e) =>{
    e.preventDefault()
    setLoadingData(true)
    // console.log('-----------------4---------data',productInfo.name)
    try {
        if (!productInfo.name ) {
            toast.error("Please Fill the all Fields", { autoClose: 500, theme: 'colored' })
            setLoadingData(false)
          }
        else {
            const { data } = await axios.put(`${process.env.REACT_APP_SECTIONS}/${sectionData._id}`,
                {
                    name: productInfo.name,
                    company :'store',
                    description:productInfo.description,
                }, {
                headers: {
                    'Authorization': authToken
                }
            })
            setOpenAlert(false);
            // console.log('-----------------4---------data',data)
            if (data.status === true) {
              toast.success("Collection Updated", { autoClose: 500, theme: 'colored' })
              // console.log('-----------------4---------data',data.data)
              setSectionData(data.data)
               setLoadingData(false)
               setOpenEdit(false)
            }
            else {
              setLoadingData(false)
                toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
            }
        }
       } catch (error) {
         setLoadingData(false)
        toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
    }
  }

  const GetProduct = () =>{
      console.log('data-------------click-------->',)
      if (authToken !== null) {
        setStep({page:2,title:'Address'})
      }else{
        setLoginOpen(true)
      }
      
  
      
  }

  const onSuccess = (reference) => {
      
  

    const CreateData = {
      reference: reference.reference,
      user:user._id,
      productDetails: JSON.stringify(products),
       userId: userData._id,
       section:sectionData._id,
       userDetails: JSON.stringify(userDetails),
      student:true,
      shiping,
      school:school === null ? null : school.label,
      where,
      connection:true,
       amount: allPrice,
       currency: "NGN",
        name: userData.firstName + ' ' + userData.lastName,
        description: "Payment",
            image: profile,
        prefill: {
            name: userData.firstName + ' ' + userData.lastName,
            email: userData.email,
            contact: userData.phoneNumber
        },
    }
    // console.log('--------------------->plan',CreateData,products)
    if(reference.status === 'success'){
   const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(CreateData),
         };
            fetch(`${process.env.REACT_APP_PAYEMENTVERIFICATION}`, requestOptions)
                   .then(res => res.json())
                   .then((res)=>{
                    if(res.data.status === true){
                        // console.log('---------------------->',res.data.status )
                        timePush()
                     }
                    
           }).catch( (error) => {
                    
      })
    }
  };




  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log("closed");
  };

  

  const StateData = [
    {  id:1, label: "Abuja (Mabushi, Jabi, and wuye)", value: "Gardening" ,amount:3900},
    { id:2,  label: "Kaduna, Lokoja or Minna", value: "Plants" ,amount:3900},
    { id:3,  label: "Abuja (maitama, Wuse, CBD,  lifecamp)", value: "Seeds" ,amount:2500},
    { id:4,  label: "North Central", value: "Bulbs" ,amount:3500},
    { id:5, label: "Abuja (Gwarimpa, Galadimawa, Apo, Asokoro)", value: "Planters" ,amount:4000},
    { id:6, label: "Abuja (Other places)", value: "Planters" ,amount:4000},
    { id:7, label: "Port Harcourt", value: "Planters" ,amount:1500},
    { id:8,   label: "Door to door delivery (Nasarawa State)", value: "Planters" ,amount:5000},
    { id:9, label: "Abuja (everywhere else)", value: "Planters" ,amount:3500},
    { id:10,  label: "Delta Line", value: "Planters" ,amount:3000},
    { id:11, label: "South West (excluding Lagos)", value: "Planters" ,amount:3500},
    {id:12,  label: "Door to door delivery (Nationwide)", value: "Planters" ,amount:4500},
    {id:13,  label: "(South East/South South)", value: "Planters" ,amount:4500},
    
   ];

   const schoolData = [
          {  id:1, label: "Sleek angels international schools", address: "30 Rumuevuorlu Rd., Off Miniorlu by Ada George Rd., PortHarcourt, Rivers State" ,zip:500001,state:'Port Harcourt', city:'Rivers State'},
    
   ];



   const containedMove = ()=>{
    setStep({page:2,title:' Shipping method'})
   }

const NextPage= ()=>{
  setAllPrice(shipData+totalProduct)
  // console.log('setShepData get',shipData+totalProduct)
    
    setStep({page:3,title:'   Order Summary         '})
}

const NextBackToOrder = ()=>{
    setStep({page:1,title:'   Order Summary '})
}


function handleChange(checkedValues) {
        // console.log('checking',checkedValues)
      setValue(checkedValues);
      let result = StateData.find(o => o.id === parseFloat(checkedValues));
      setShipData(result.amount)
      // passData(result)
    //   console.log(result)
 }

 const handlePickSchool = (data)=>{
  //  console.log('pick school-------------->',data)
    setSchoolSelected(data)
    let result = schoolData.find(o => o.id === parseFloat(data));
     setSchool(result)
     userDetails.address = result.address;
     userDetails.zipCode = result.zip;
     userDetails.city = result.city;
     userDetails.userState = result.state;
    //  console.log('pick school-------------->',result,'userDetails-------------------->',userDetails)
 }

 function ToSeoUrl(url) {
        
  // make the url lowercase         
  var encodedUrl = url.toString().toLowerCase(); 

  // replace & with and           
  encodedUrl = encodedUrl.split(/\&+/).join("-and-")

  // remove invalid characters 
  encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");       

  // remove duplicates 
  encodedUrl = encodedUrl.split(/-+/).join("-");

  // trim leading & trailing characters 
  encodedUrl = encodedUrl.trim('-'); 

  return encodedUrl; 
}

const handleToLogin = () => {
  navigate('/login')
};


const timePush =(data)=>{
setTimeout(function(){

    navigate('/')
}, 1500);
}


    return (
     
           <>
           {
            sectionData == null?
            null
            :
             <Helmet
                title={sectionData.name}
                meta={[
                { name: sectionData.name, content: shareUrl },

                { name: 'twitter:site', content: sectionData.name },
                { name: 'twitter:creator', content:sectionData.name },
                { name: 'twitter:title', content: sectionData.name },
                { name: 'twitter:image', content: sectionData.name },

                { property: 'og:title', content: sectionData.name },
                { property: 'og:site_name', content: shareUrl+'/'+sectionData.name+'/'+sectionData._id},
                { property: 'og:type', content: shareUrl },
                { property: 'og:url', content:shareUrl+'/'+sectionData.name+'/'+sectionData._id },
                { property: 'og:description', content:sectionData.name},
                { property: 'og:image', content:sectionData.name},
                { property: 'og:site_name',content: shareUrl },

                { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                { name: 'apple-itunes-app', content: 'app-id=1125423676' },
                ]}
               />
              }
            {
              step.page === 1?
                    <div>
                    {
                      loading === true?
                        <div style={{paddingTop:'7%',alignItems: 'center',justifyContent: 'center',width:'25%',margin:'auto'}}>
                        <p  style={{textAlign:'center'}} ><CircularProgress /></p>
                        </div>
                        :
                      
          
                        <div className='collection-detail'>
                            {
                              sectionData == null?
                              null
                              :
                              <div className='collectionDetail-moblie'>
                                  <div className='collectionDetail-price'>
                              
                                      <div className='collectionDetail-listName'>
                                      <h3>{sectionData.name} </h3>
                                      <h4> {moneyFormat(totalProduct)} </h4>
                                        
                                      </div>
                                      <div className='collectionDetail-share'>
                                          <Button variant='contained' className='all-btn ' style={{ background:'red' }}  startIcon={<MdAddShoppingCart />} onClick={(() => GetProduct())}>Buy</Button>
                                          <Button style={{ marginLeft: 10 }} variant='contained' className='all-btn' startIcon={<AiOutlineShareAlt />} onClick={() => shareProduct(sectionData)}>Share</Button>
                                      </div>
                                    </div>
                                    
                               </div>
                                }
                          
                            <div className='collection-detail-show'>
                            {
                              sectionData == null?
                              null
                              :
                              <div >
                              
                                  {
                                    showProductData  === true ?
                                          <div>
                                            <a href='#' style={{float:'right',position:'relative',top:'3%'}} onClick={()=>setShowProductData(false)}>
                                            <AiFillCloseCircle style={{ fontSize: 25 }}/>  
                                            </a> 
                                              {loading ? (
                                                        <Skeleton variant='rectangular' height={400} />
                                                    ) : (
                                                        <div className="product-image">
                                                        {
                                                          images.length  === 0 ?
                                                          <h3 className='productDetail-title'>{sectionData.name} </h3>
                                                          :
                                                          <ImageGallery items={images} />
                                                        }
                                                          
                                                        </div>
                                                    )} 
                                            </div>
                                        :
                                        <div className='collectionDetail-image-url'>
                                          {
                                            sectionData.url ?
                                            <img src={sectionData.url} />
                                            :
                                            <div style={{ alignItems: 'center',justifyContent: 'center',textAlign:'center',paddingTop:'5%'}}>
                                                  <BsImage  style={{ fontSize: 200 }}/> 
                                                    <br/>
                                                  <h1> No Image Yet</h1>
                                            </div>
                                          }
                                              
                                        </div>  
                                    }
                                        
                              </div>
                              }
                              {
                                showProductData  === true ?
                                <div>
                                    {
                                      section === null?
                                      null
                                      :
                                      <div style={{width:'95%'}}>
                                          <h3>{section.name}</h3>  
                                          <h3>{moneyFormat(section.price)}</h3> 
                                          <Rating name="read-only" value={Math.round(section.rating)} size='small'  readOnly precision={0.5} /> 
                                          <br/>
                                          {section.description}
                                    </div>
                                    }
                                </div>
                                :
                                null
                              }
                             
                              
                            </div>
                            {
                              sectionData == null?
                              null
                              :
                              <div className='collectionDetail-list'>
                                  <div className='collectionDetail-web'>
                                      <div className='collectionDetail-price'>
                                        <h2>{moneyFormat(totalProduct)}</h2>
                                  
                                          <Button variant='contained' className='all-btn ' style={{ background:'red' }}  startIcon={<MdAddShoppingCart />} onClick={(() => GetProduct())}>Buy</Button>
                                          <Button style={{ marginLeft: 10 }} variant='contained' className='all-btn' startIcon={<AiOutlineShareAlt />} onClick={() => shareProduct(sectionData)}>Share</Button>
                                  
                                      </div>
                                      <div className='collectionDetail-listName'>
                                          <h3>{sectionData.name} </h3>
                                          
                                      </div>
                                      
                                        
                                  
                                    </div>
                                  
                                    {
                                      loadingData === true?
                                          <div style={{marginTop:'10%',alignItems: 'center',justifyContent: 'center',width:'10%',margin:'auto'}}>
                                                <p  style={{textAlign:'center'}} >  <CircularProgress  /> </p>
                                          </div>
                                        :
                                        <div className='collectionDetail-list-sub'>
                                        {
                                          products.map(data => (
                                            <div className='collectionDetail-product'>
                                                <a href='#' onClick={()=>ClickToShow(data)}>
                                                  <div className='collectionDetail-product-img'>
                                                        <img src={data.images[0].url}/> 
                                                    </div>
                                                  </a>  
                                                    <h6>  {data.name.length > 35 ? data.name.slice(0, 35) + '...' : data.name}</h6>
                                                    <h6>  {moneyFormat(data.price)}</h6>
                                                    <p className='collectionDetail-quantity'><a href="#"> Q{data.quantity}</a></p>
                                                    <Rating name="read-only" value={Math.round(data.rating)} size='small'  readOnly precision={0.5} /> 
                                            </div>
                                            ))
                                          }
                                        </div>
                                      }
                                   {
                                        sectionData === null ?
                                      null
                                      :
                                        <p style={{marginTop:'5%',padding:'2%'}}>
                                        <MarkdownPreview source={sectionData.description} />
                                      
                                        </p>
                                    }
                                   {
                                    sectionData  === null || undefined ?
                                    null
                                    :
                                    <div style={{display:'flex',marginTop:'5%',marginLeft:'5%'}}>
                                            <Avatar size='small' alt="Customer Avatar" />
                                          
                                            
                                            {
                                              sectionData. owner.firstName === undefined ?
                                              <p>
                                                <p>Project Created by</p>
                                                <h4 style={{ marginLeft:'2px', textAlign: "left" }}>{sectionData.owner.firstname + ' ' + sectionData.owner.lastname}</h4>
                                              </p>
      
                                              :
                                              <p>
                                              
                                                <p>Project Created by</p>
                                                <h4 style={{ marginLeft:'2px', textAlign: "left" }}>{sectionData.owner.firstName + ' ' + sectionData.owner.lastName}</h4>
                                                
                                              </p>
                                              
                                            }
                                      </div>
                                   }
                        
                                       <CollectionReview setProceed={setProceed} authToken={authToken} id={id} setOpenAlert={setOpenAlert} />
                                  </div>
                                }
                                  
                               
                            </div>
                            }
          
                                <Dialog
                                    open={shareMoald}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleClose}
                                    aria-describedby="alert-dialog-slide-description"  >
                                  
                                    <DialogContent sx={{ width: { xs: 180, md: 550, xl: 200 },  justifyContent: 'center' }}>
                                      <h3>Share Projects</h3>
                                      <br/>
                                          <div className='collectDetail-share'>
                                          {
                                            sectionData === null ?
                                            null
                                            :
                                                <ul>
                                                  <li>
                                                      <FacebookShareButton
                                                        url={shareUrl+'/'+ToSeoUrl(sectionData.name)+'/'+sectionData._id}
                                                        quote={sectionData.name  +' ' + sectionData.description }
                                                        hashtag={sectionData.name }  >
                                                        <FacebookIcon size={40} round={true} />
                                                      </FacebookShareButton>
                                                  </li>
                                                  <li>
                                                      <WhatsappShareButton
                                                      url={shareUrl+'/'+ToSeoUrl(sectionData.name)+'/'+sectionData._id}
                                                      quote={sectionData.name  +' ' + sectionData.description }
                                                      hashtag={sectionData.name } >
                                                        <WhatsappIcon size={40} round={true} />
                                                    </WhatsappShareButton>
                                                  </li>
                                                  <li>
                                                      <TwitterShareButton
                                                      url={shareUrl+'/'+ToSeoUrl(sectionData.name)+'/'+sectionData._id}
                                                        quote={sectionData.name  +' ' + sectionData.description }
                                                        title={sectionData.name  +' ' + sectionData.description }
                                                        via={sectionData.name }
                                                        hashtag={sectionData.name } >
                                                        <TwitterIcon size={40} round={true} />
                                                    </TwitterShareButton>
                                                  </li>
                                                  <li>
                                                    <TelegramShareButton
                                                    url={shareUrl+'/'+ToSeoUrl(sectionData.name)+'/'+sectionData._id}
                                                        quote={sectionData.name  +' ' + sectionData.description }
                                                        hashtag={sectionData.name }>
                                                    <TelegramIcon size={40} round={true} />
                                                    </TelegramShareButton>
                                                  </li>
                                                  <li>
                                                    <LinkedinShareButton
                                                    url={shareUrl+'/'+ToSeoUrl(sectionData.name)+'/'+sectionData._id}
                                                        quote={sectionData.name  +' ' + sectionData.description }
                                                        summary={sectionData.name  +' ' + sectionData.description }
                                                        hashtag={sectionData.name }>
                                                    <LinkedinIcon size={40} round={true} />
                                                    </LinkedinShareButton>
                                                  </li>
          
                                                  <li>
                                                    <PinterestShareButton
                                                    url={shareUrl+'/'+ToSeoUrl(sectionData.name)+'/'+sectionData._id}
                                                        quote={sectionData.name  +' ' + sectionData.description }
                                                        hashtag={sectionData.name }>
                                                    <PinterestIcon size={40} round={true} />
                                                    </PinterestShareButton>
                                                  </li>
                                                  <li>
                                                    <EmailShareButton
                                                    url={shareUrl+'/'+ToSeoUrl(sectionData.name)+'/'+sectionData._id}
                                                        quote={sectionData.name  +' ' + sectionData.description }
                                                        hashtag={sectionData.name }>
                                                    <EmailIcon size={40} round={true} />
                                                    </EmailShareButton>
                                                  </li>
                                                  <li>
                                                    <FacebookMessengerShareButton
                                                    url={shareUrl+'/'+ToSeoUrl(sectionData.name)+'/'+sectionData._id}
                                                        quote={sectionData.name  +' ' + sectionData.description }
                                                        hashtag={sectionData.name }>
                                                    <FacebookMessengerIcon size={40} round={true} />
                                                    </FacebookMessengerShareButton>
                                                  </li>
                                              </ul>
                                          }
                                          </div>
                                      </DialogContent>
                                </Dialog>

                          <Dialog
                                open={loginOpen}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={() => handleClose(setOpenAlert)}
                                aria-describedby="alert-dialog-slide-description"
                            >
                              <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 }, display: 'flex', justifyContent: 'center' }}>
                                  <Typography variant='h5'> Please Login To Proceed</Typography>
                              </DialogContent>
                              <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                  <Button variant='contained' onClick={handleToLogin} endIcon={<AiOutlineLogin />} color='primary'>Login</Button>
                                  <Button variant='contained' color='error' endIcon={<AiFillCloseCircle />} onClick={handleClose}>Close</Button>
                              </DialogActions>
                         </Dialog>
                    </div>
                : step.page  === 2?
                   <div>
                       <div className={ 'collection-detail'}>
                             <div className={'collection-detailPage-2'}>
                               {
                                  products.map(data => (
                                    <div className='collectionDetail-product'>
                                        <a href='#' onClick={()=>ClickToShow(data)}>
                                          <div className='collectionDetail-product-img'>
                                                <img src={data.images[0].url}/> 
                                            </div>
                                          </a>  
                                            <h6>  {data.name.length > 32 ? data.name.slice(0, 32) + '...' : data.name}</h6>
                                            <h6>  {moneyFormat(data.price)}</h6>
                                          
                                            <Rating name="read-only" value={Math.round(data.rating)} size='small'  readOnly precision={0.5} /> 
                                    </div>
                                    ))
                                  }
                            </div>
                            <div className='collectionPeblic-show'>
                            {
                            step.page ===1 ?
                                <Typography variant="div" component="h1">
                                    {" "}
                                Order 
                                </Typography>
                                :step.page ===2 ?
                                <Typography variant="div" component="h1">
                                    {" "}
                                Shipping method
                                </Typography>
                                :step.page === 3?
                                <Typography variant="div" component="h1">
                                    {" "}
                                Order Summary 
                                </Typography>
                                :
                                null
                                
                            }
                            <div>
                            {StateData.map((item) => {
                              return (
                                  <a onClick={()=>handleChange(item.id)} className={item.id === parseFloat(value) ?'orderSummary-select':'orderSummary'}>
                                          <Box   className='orderSummary'>
                                          <Typography variant="body1" variant="h7" component="div" >
                                                  <Checkbox
                                                  key={item.label}
                                                  // onChange={handleChange}
                                                  checked={item.id === parseFloat(value)}
                                                  value={item.id}
                                              >
                                                  {item.value}
                                                  
                                              </Checkbox>
                                                      {item.label}
                                                  </Typography>
                                                  <Typography variant="h7" component="div" >
                                                      {moneyFormat(item.amount)}
                                                  </Typography>
                                                  </Box>
                                          </a>
                                      );
                                  })}
          
                                  <Container sx={{ display: 'flex', gap: 10, justifyContent: 'center', marginTop: 5 }}>
                                      <Button variant='contained' endIcon={<MdUpdate />} onClick={()=>NextBackToOrder()}>Back</Button>
                                      <Button variant='contained' endIcon={<BsFillCartCheckFill />} onClick={()=>NextPage()}  > Next</Button>
                                  </Container>
                               </div>   
                        
                            </div>
                            
                      </div>
                  </div>
               : step.page  === 3?
               <div>
                  <div className={ 'collection-detail'}>
                    <Container sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: 10 }}>
                      <Typography variant='h6' sx={{ margin: '20px 0' }}>Checkout</Typography>
                      {
                        user === null || undefined ?
                        null
                        :
                        <form noValidate autoComplete="off"  onSubmit={checkOutHandler} >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField inputProps={{ readOnly: true }} disabled label="First Name" name='firstName' value={userDetails.firstName || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField inputProps={{ readOnly: true }} disabled label="Last Name" name='lastName' value={userDetails.lastName || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField inputProps={{ readOnly: true }} disabled label="Contact Number" type='tel' name='phoneNumber' value={userDetails.phoneNumber || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField inputProps={{ readOnly: true }} disabled label="Email" name='userEmail' value={userDetails.userEmail || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid  style={{width:'100%'}}>
                            <FormControlLabel
                                control={
                                  <Checkbox checked={antoine} onChange={()=>setAntoine(!antoine)} name="antoine" 
                                  sx={{ '& .MuiSvgIcon-root': { fontSize: 30} }}
                                  />
                                }
                                label="Student"
                             />
                             </Grid>
                             <br/>
                             {
                              antoine ?
                              <Grid  style={{width:'100%',}}>
                                {schoolData.map((item) => {
                                  return (
                                            <a onClick={()=>handlePickSchool(item.id)}  style={{width:'100%'}}>
                                          
                                              <div className={item.id === parseFloat(schoolSelected) ?'publiceCollection-school':'publiceCollection-school-null'} >
                                                    <Checkbox
                                                    key={item.label}
                                                    // style={{float:'right'}}
                                                    // onChange={handleChange}
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 30} }}
                                                    checked={item.id === parseFloat(schoolSelected)}
                                                    value={item.id}
                                                >
                                                    {item.value}
                                                    
                                                </Checkbox>

                                                <h3>
                                                <IoIosSchool />
                                                </h3>
                                                  
                                              
                                                <p>   {item.label}</p>
                                               
                                                
                                                
                                                
                                              </div>
                                            </a>
                                        )
                                    })}
                               </Grid>
                              :
                               <>
                               <Grid item xs={12}>
                               <TextField label="Address" name='address' value={userDetails.address || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField label="City" name='city' value={userDetails.city || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField type='tel' label="Postal/Zip Code" name='zipCode' value={userDetails.zipCode || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField label="Province/State" name='userState' value={userDetails.userState || ''} onChange={handleOnchange} variant="outlined" fullWidth />
                                </Grid>
                               </>
                               
                               
                             }
                                
                          
                        </Grid>
                        <Container sx={{ display: 'flex', gap: 10, justifyContent: 'center', marginTop: 5 }}>
                            <Link to='/update'> <Button variant='contained' endIcon={<MdUpdate />}>Update</Button></Link>
                            <Button variant='contained' endIcon={<BsFillCartCheckFill />} type='submit' >Checkout</Button>
                        </Container>
                    </form >
                      }
                    
      
                      
      
                  </Container >
                  </div>
               </div>
               :
               null
            }

          
          </>
    )
}

export default CollectionPublish