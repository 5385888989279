import { Computers, SchoolsAccessories, Electronics, Phone,Tablet, Robotics, Books, laboratory } from '../Assets/Images/Image';
const data = [
    {
        img: Electronics,
        name: "electronic"
    },

    {
        img: Robotics,
        name: "robotics"
    },
    {
        img: Computers,
        name: "computers"
    },
    {
        img: Phone,
        name: "phone"
    },
    {
        img: Tablet,
        name: "tablet"
    },
   
    {
        img: laboratory,
        name: "laboratory"
    },
]
export default data