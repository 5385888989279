import React, { useEffect ,useState} from 'react'
import axios from 'axios'
import {
   Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider,Tooltip, Grid, TextField, Typography, InputLabel, MenuItem, FormControl, Select, CircularProgress
} from '@mui/material';
import { Link, useParams } from 'react-router-dom'
import { Box } from '@mui/system'
import { useContext } from 'react'
import { ContextFunction } from '../Context/Context'
import { getCart, getWishList, handleLogOut, handleClickOpen, Transition } from '../Constants/Constant'
// import CategoryCard from '../../Components/Category_Card/CategoryCard';

import Slider from "react-slick";
import {BiDownArrow ,BiSolidDownArrow} from 'react-icons/bi'
// import ProductCard from './ProductCollectionCard'
// import './homepage.css'
// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";
const  CollectionCard = (props) => {
    const { setCart } = useContext(ContextFunction)
    const [productData, setProductData] = useState([])
    const [loading,setLoading] = useState(false);
    // const [section,setSection] = useState(null);
    const [sections , seSsections] = useState(null)
    const [openAlert, setOpenAlert] = useState(false);
    let authToken = localStorage.getItem('Authorization')
    useEffect(() => {
      setLoading(true)
  
 
        window.scroll(0, 0)
    }, [])



  const handleClose = () => {
    setOpenAlert(false);
    // setOpenEdit(false)
};

const CountTotal = (data)=> {
   
    var totaltAll = data.reduce(function(res,item) {
      // console.log('-------CountTotal----------------->',item)
      return res + (item.price * item.quantity);
    }, 0);
    // setTotalProduct(totaltAll)
    return totaltAll
    // console.log(taltAllto);

}
 


const  moneyFormat =(price, sign = 'N') =>{
  const pieces = parseFloat(price).toFixed(2).split('')
  let ii = pieces.length - 3
  while ((ii-=3) > 0) {
    pieces.splice(ii, 0, ',')
  }
  return sign + pieces.join('')
}
 
   
   const clickModal =()=>{
    setOpenAlert(true)
   }


   const SendDataSelect = (data) =>{
    props. pickSelection(data)
    // props.SendDataToCallection(data)
   }
   
   const SendDataToCallection = (data) =>{

   }

    return (
           <>
              {
                 props.section === null ?
                  <div className='collectionslist-full'>
                      <div className='collectionslist-body' >
                      <div className='collectionslist-detail'>
                          <Link to={`/project`}>
                                <h5>Clcik </h5>
                                <h6>To Create Collection </h6>
                          </Link>
                      </div>
                      <div className='collectionslist-product' style={{paddingTop:'1%'}}>
                      <Link to={`/project`}>
                         No Collection
                      </Link>
                      </div>
                      <div className='collectionslist-price'>
                          {
                            props.section === null ?  
                              null
                            :
                            <p><a onClick={()=>clickModal()}><BiSolidDownArrow style={{ fontSize: 29 }}/></a></p>  
                          }
                            
                                              
                         </div>
                       </div>
                    </div>
                  
                   :
                   <div className='collectionslist-full'>
                     <div className='collectionslist-body' >
                          <div className='collectionslist-detail-body'>
                             <Link to={`/project/${props.section._id}`}>
                                <div className='collectionslist-card'>
                                    <div className='collectionslist-detail'>
                                        <h5>{props. section.name}</h5>
                                        <h6>{props.products.length} products</h6>
                                    </div>
                                    <div className='collectionslist-product'>
                                        {props.products.map(item => (
                                            <div className='collectionslist-sub'>
                                          <img src={item.images[0].url} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                             </Link>
                         </div>
                          <div className='collectionslist-price'>
                              <p><a onClick={()=>clickModal()}><BiSolidDownArrow /></a></p>  
                              <h4><a onClick={()=>clickModal()}> {moneyFormat(CountTotal(props.products))}</a></h4> 
                                                  
                          </div>
                      </div>
                    </div>
              }
                
                
                <Dialog
                open={openAlert}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogContent className="collectionslist-modalBody">
                {props.sections.map(prod => (
                   <a onClick={()=>SendDataSelect(prod)}>
                      <div className='collectionslist-modal' >
                          <div className='collectionslist-detail-modal'>
                                <h5>{prod.name}</h5>
                                <h6> <small>{prod.products.length} Products</small> </h6>
                            </div>
                            <div className='collectionslist-product-modal'>
                            {prod.products.map(item => (
                                  <div className='collectionslist-sub-modal'>
                                  <img src={item.images[0].url} />
                                  </div>
                            ))}
                                  
                            </div>
                        </div>
                        </a>
                     ))}
                </DialogContent>
             </Dialog>
           
        </ >
    )
}

export default CollectionCard