import { Box, Button, Card, CardActions, CardContent, Grid, Typography,Container ,FormControlLabel,Checkbox } from '@mui/material'
import React, { useContext, useEffect, useState,useRef } from 'react'
import { BsFillCartCheckFill } from 'react-icons/bs'
import { IoBagCheckOutline } from 'react-icons/io5'
import { MdUpdate } from 'react-icons/md'


const  moneyFormat =(price, sign = 'N') =>{
    const pieces = parseFloat(price).toFixed(2).split('')
    let ii = pieces.length - 3
    while ((ii-=3) > 0) {
      pieces.splice(ii, 0, ',')
    }
    return sign + pieces.join('')
  }


const OrderSummary = ({ proceedToCheckout, total, shippingCoast,passData }) => {
    const [step ,setStep]= useState({page:1,title:'pick state'})
    const [value, setValue] = useState(1);
    const [shipData, setShepData] = useState(null);

    function handleChange(checkedValues) {
        console.log('checking',checkedValues)
      setValue(checkedValues);
      let result = StateData.find(o => o.id === parseFloat(checkedValues));
      setShepData(result.amount)
      passData(result)
    //   console.log(result)
    }


       const StateData = [
        {  id:1, label: "Abuja (Mabushi, Jabi, and wuye)", value: "Gardening" ,amount:3900},
        { id:2,  label: "Kaduna, Lokoja or Minna", value: "Plants" ,amount:3900},
        { id:3,  label: "Abuja (maitama, Wuse, CBD,  lifecamp)", value: "Seeds" ,amount:2500},
        { id:4,  label: "North Central", value: "Bulbs" ,amount:3500},
        { id:5, label: "Abuja (Gwarimpa, Galadimawa, Apo, Asokoro)", value: "Planters" ,amount:4000},
        { id:6, label: "Abuja (Other places)", value: "Planters" ,amount:4000},
        { id:7, label: "Port Harcourt", value: "Planters" ,amount:1500},
        { id:8,   label: "Door to door delivery (Nasarawa State)", value: "Planters" ,amount:5000},
        { id:9, label: "Abuja (everywhere else)", value: "Planters" ,amount:3500},
        { id:10,  label: "Delta Line", value: "Planters" ,amount:3000},
        { id:11, label: "South West (excluding Lagos)", value: "Planters" ,amount:3500},
        {id:12,  label: "Door to door delivery (Nationwide)", value: "Planters" ,amount:4500},
        {id:13,  label: "(South East/South South)", value: "Planters" ,amount:4500},
        
       ];

    const containedMove = ()=>{
        setStep({page:2,title:' Shipping method'})
    }

    const NextPage= ()=>{
        setStep({page:3,title:'   Order Summary         '})
    }

    const NextBackToOrder = ()=>{
        setStep({page:1,title:'   Order Summary '})
    }

    return (
        <Card
            sx={{ width: { xs: 450, sm: 550, md: 550, lg: 700 } }}
            // className={classes.root}

            elevation={15}
        >
            <CardContent >
            {step.page ===1 ?
                <Typography variant="div" component="h1">
                    {" "}
                Order 
                </Typography>
                :step.page ===2 ?
                <Typography variant="div" component="h1">
                    {" "}
                Shipping method
                </Typography>
                :step.page === 3?
                <Typography variant="div" component="h1">
                    {" "}
                Order Summary 
                </Typography>
                :
                null
                
            }
               
                <Typography variant="subtitle2">
                    <hr />
                </Typography>

                {step.page ===1 ?
                 <div>
                   <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }} >
                            <Typography variant="body1" component="div" color='primary'>
                                SubTotal
                            </Typography>
                            <Typography variant="h6" component="div" color='primary'>
                                {moneyFormat(total - shippingCoast)}
                            </Typography>
                        </Box>
                        
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                            <Typography variant="body1" component="div" color='primary'>
                                Total
                            </Typography>
                            <Typography variant="h6" component="div" color='primary'>
                                {moneyFormat(total)}
                            </Typography>
                        </Box>
                      </Grid>
                      <CardActions sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                            <Button variant='contained' size='large' endIcon={<IoBagCheckOutline />} color="primary" onClick={()=>containedMove()}>
                            Next 
                            </Button>
                        </CardActions>
                 </div>   
               
                :step.page ===2 ?
                  <div>
                  {StateData.map((item) => {
                    return (
                        <a onClick={()=>handleChange(item.id)} className={item.id === parseFloat(value) ?'orderSummary-select':'orderSummary'}>
                                <Box   className='orderSummary'>
                                <Typography variant="body1" variant="h7" component="div" >
                                        <Checkbox
                                        key={item.label}
                                        // onChange={handleChange}
                                        checked={item.id === parseFloat(value)}
                                        value={item.id}
                                    >
                                        {item.value}
                                        
                                    </Checkbox>
                                            {item.label}
                                        </Typography>
                                        <Typography variant="h7" component="div" >
                                            {moneyFormat(item.amount)}
                                        </Typography>
                                        </Box>
                                </a>
                            );
                        })}

                        <Container sx={{ display: 'flex', gap: 10, justifyContent: 'center', marginTop: 5 }}>
                            <Button variant='contained' endIcon={<MdUpdate />} onClick={()=>NextBackToOrder()}>Back</Button>
                            <Button variant='contained' endIcon={<BsFillCartCheckFill />} onClick={()=>NextPage()}  > Next</Button>
                        </Container>
                    </div>
                  :step.page === 3 ?
                  <div>
                  <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                       <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }} >
                           <Typography variant="body1" component="div" color='primary'>
                               SubTotal
                           </Typography>
                           <Typography variant="h6" component="div" color='primary'>
                               {moneyFormat(total - shippingCoast)}
                           </Typography>
                       </Box>
                       <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }} >
                           <Typography variant="body1" component="div" color='primary'>
                               Shipping
                           </Typography>
                           <Typography variant="h6" component="div" color='primary'>
                               {moneyFormat(shippingCoast)}
                           </Typography>
                       </Box>
                       <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                           <Typography variant="body1" component="div" color='primary'>
                               Total
                           </Typography>
                           <Typography variant="h6" component="div" color='primary'>
                               {moneyFormat(total)}
                           </Typography>
                       </Box>
                     </Grid>
                    
                    </div>
                    :
                    null   
                  
                }
            </CardContent>
            {
              step.page ===3 ?
                    <CardActions sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                        <Button variant='contained' size='large' endIcon={<IoBagCheckOutline />} color="primary" onClick={proceedToCheckout}>
                            Checkout 
                        </Button>
                    </CardActions>
               :
                null
            }
            
           
        </Card >
    )
}

export default OrderSummary