import { Card, CardActionArea, CardActions, Rating, CardContent, Typography } from '@mui/material';
import { Box } from '@mui/system';
import styles from './ProductCard.module.css'
import { Link, useParams } from 'react-router-dom'
import {MdOutlineAdd,MdDeleteOutline } from 'react-icons/md'
import {GrAdd} from 'react-icons/gr'


export default function ProductCard({ prod ,to ,sendData}) {


    const  moneyFormat =(price, sign = 'N') =>{
        const pieces = parseFloat(price).toFixed(2).split('')
        let ii = pieces.length - 3
        while ((ii-=3) > 0) {
          pieces.splice(ii, 0, ',')
        }
        return sign + pieces.join('')

        
      }
    
     const CallAddData = (data) =>{
         sendData(data)
     }
    
      
    return (
        <div className='collectionList-product-card'>
                <Link to={to}>
                    <CardActionArea className={styles.card_action}>
                    <div className='collectionDetail-product-img'>
                            <img alt={prod.name} src={prod.images[0].url} loading='lazy' className={styles.cart_img} />
                      </div>
                
                    </CardActionArea>
                    </Link>   
                    <Link to={to}>
                        <p>
                            {prod.name.length > 30 ? prod.name.slice(0, 30) + '...' : prod.name}
                        </p>
               
                    </Link>
                    <CardActions style={{ display: "flex", justifyContent: "space-between", width: '100%' }}>
                    <Link to={to}>
                        <Typography variant="h7" color="primary">
                            {moneyFormat(prod.price)}
                            <Rating precision={0.5} name="read-only" value={prod.rating} size="small" readOnly />
                        </Typography>
                        </Link>
                        <Typography >
                            <button className="buttom-make collection-data" onClick={()=>CallAddData(prod)}>
                              <MdOutlineAdd style={{ fontSize: 29 , color:'#ffffff'}}/>   Collection
                            </button>
                            <button className="buttom-make collection-data-moblie" onClick={()=>CallAddData(prod)}>
                              <MdOutlineAdd />   
                            </button>
                        </Typography>
             </CardActions>
        </div>
    );
}