import React from 'react'
import styles from './Category.module.css'
import { Link } from 'react-router-dom'

const CategoryCard = ({ data }) => {
    return (
        <Link to={`product/type/${data.name}`}>
            <div  >
                
                <span style={{fontSize:'20px'}}>{data.name}</span>
            </div>
        </Link>
    )
}

export default CategoryCard