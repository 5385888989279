import './singlecategory.css'
import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { Container } from '@mui/system'
import { Box, Button, MenuItem, FormControl, Select,CircularProgress,TextField } from '@mui/material'
import { AiOutlineHeart, AiOutlineShoppingCart, AiFillCloseCircle,AiOutlineClose } from 'react-icons/ai'
import { toast } from 'react-toastify';
import Loading from '../Components/loading/Loading'
import { BiFilterAlt } from 'react-icons/bi';
import ProductCard from '../Components/Card/Product Card/ProductCard'
import CopyRight from '../Components/CopyRight/CopyRight'
import  UpdateProduct from "../function/UpdateProduct";


const SingleCategory = () => {

    const [productData, setProductData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [filterOption, setFilterOption] = useState('All')
    const [title, setTitle] = useState('All')
    const [searchTerm, setSearchTerm] = useState('');
    const [productOld, setProductOld] = useState([])
    const [closeSearch,setCloseSearch]= useState(false);
    const { cat } = useParams()
    let authToken = localStorage.getItem('Authorization')

    useEffect(() => {
        getCategoryProduct()
        window.scroll(0, 0)
    }, [])

    const getCategoryProduct = async () => {
        try {
            setIsLoading(true)
            const { data } = await axios.post(`${process.env.REACT_APP_PRODUCT_TYPE}`, { userType: cat.toLowerCase() })
            // console.log('------------------>',data,cat)
            setIsLoading(false)
            setProductData(data)

        } catch (error) {
            console.log(error);
        }
    }

    const productFilter = []

    if (cat === 'book') {
        productFilter.push('All', 'Scifi', 'Business', 'Mystery', 'Cookbooks', 'Accessories', 'Price Low To High', 'Price High To Low', 'High Rated', 'Low Rated')
    }
    else if (cat === 'robotics') {
        productFilter.push('Autonomous Mobile Robots', 'Automated Guided Vehicles','Articulated Robots','Humanoids','Cobots','Hybrids')
    }
    else if (cat === 'computers') {
        productFilter.push('Hybrid Computer', 'Digital Computer', 'Analogue Computer')
    }
    else if (cat === 'phone') {
        productFilter.push('Smart Phones', 'Camera Phones', 'Music Phones','3G Phones')
    }
    else if (cat === 'tablet') {
        productFilter.push('Apple iPad', 'Android Tablets')
    }
    else if (cat === 'accessories') {
        productFilter.push('All', 'Running', 'Football', 'Formal', 'Casual', 'Price Low To High', 'Price High To Low', 'High Rated', 'Low Rated')
    }
    else if (cat === 'laboratory') {
        productFilter.push('All', 'Running', 'Football', 'Formal', 'Casual', 'Price Low To High', 'Price High To Low', 'High Rated', 'Low Rated')
    }
    else if (cat === 'electronic') {
        productFilter.push('Autonomous Mobile Robots', 'Automated Guided Vehicles','Articulated Robots','Humanoids','Cobots','Hybrids')

    }
    else if (cat === 'jewelry') {
        productFilter.push('All')

    }

    const handleChange = (e) => {
        setFilterOption(e.target.value.split(" ").join(""))
        setTitle(e.target.value)
    }
    // pricelowtohigh 
    // pricehightolow
    // highrated
    // lowrated
  
    const getData = async () => {
        setIsLoading(true)
        const filter = filterOption
        const { data } = await axios.post(`${process.env.REACT_APP_PRODUCT_TYPE_CATEGORY_}`, { userType: cat, userCategory: filter })
        setProductData(data)
        setProductOld(data)
        setIsLoading(false)
    }
    useEffect(() => {
        getData()
    }, [filterOption])


    const handleSearch = async (event) => {
        // console.log("data ----false--article----3->",event.target.value)
        try {
       
        setSearchTerm(event.target.value);
        setCloseSearch(true)
           if(event.target.value.length >= 2){
            
            // console.log('--------------new enter--------data',)
                  const { data } = await axios.get(`http://localhost:43000/api/product/product/search/?search=${event.target.value}`,
                 {
                      headers: {
                          'Authorization': authToken
                      }
                  })
      
                //   console.log('--------------res---4---------',data.data            )
                  if (data.status === true) {
                    if(data.data === undefined){
      
                    }else{
                      setProductData(data.data)
                    }
                   
                    // toast.success("added", { autoClose: 500, theme: 'colored' })
                    
                  }
      
                }
      
                } catch (error) {
                  toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
              }
      };


      const handleClickShowPassword = () =>{
        // console.log("data ----false--article----3->",)
        setCloseSearch(!closeSearch)
        setProductData(productOld)
        setSearchTerm('')
      }
      


    function ToSeoUrl(url) {
        
        // make the url lowercase         
        var encodedUrl = url.toString().toLowerCase(); 
      
        // replace & with and           
        encodedUrl = encodedUrl.split(/\&+/).join("-and-")
      
        // remove invalid characters 
        encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");       
      
        // remove duplicates 
        encodedUrl = encodedUrl.split(/-+/).join("-");
      
        // trim leading & trailing characters 
        encodedUrl = encodedUrl.trim('-'); 
      
        return encodedUrl; 
      }
    
      
    const loading = isLoading ?
        (
            <Container maxWidth='xl' style={{ marginTop: 10, display: "flex", justifyContent: "center", flexWrap: "wrap", paddingLeft: 10, paddingBottom: 20 }}>
                <Loading /><Loading /><Loading /><Loading />
                <Loading /><Loading /><Loading /><Loading />
            </Container >
        )
        : ""
    return (
        <>
            
                {loading}

                <div className="Single-searchBody">
                        <TextField
                            id="search"
                            size='small'
                            label="Search Products"
            
                            value={searchTerm}
                            onChange={handleSearch}
                            className={'collectionList-search'}
                            
                        />
                        {closeSearch ?   <a onClick={handleClickShowPassword}><AiOutlineClose  style={{ fontSize: 25 , color:'red',marginTop:'2%',marginLeft:'2%'}}  /> </a>: null}
                </div>
                  
                  {
                    loading === true?
                       <div style={{paddingTop:'7%',alignItems: 'center',justifyContent: 'center',width:'25%',margin:'auto'}}>
                       <p  style={{textAlign:'center'}} ><CircularProgress /></p>
                      </div>
                      :
                    <div>
                      <div  className='Homepage-product'>
                
                            { productData.map(prod => (
                                
                                    <UpdateProduct prod={prod} to={`/Detail/type/category/${ToSeoUrl(prod.name)}/${prod._id}`} />

                              
                            ))}
                       </div>
                    </div>
                  }
               
   
            <CopyRight sx={{ mt: 8, mb: 10 }} />
        </>
    )
}


export default SingleCategory

          