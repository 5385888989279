import './Collection.css'
import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineHeart, AiOutlineShoppingCart, AiFillCloseCircle } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { FiLogOut,FiEdit } from 'react-icons/fi'
import {MdOutlineAdd,MdDeleteOutline } from 'react-icons/md'
import axios from 'axios';
import { Link, NavLink, useNavigate ,useParams} from 'react-router-dom';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider,Tooltip, Grid, TextField,DialogContentText,
   Typography, InputLabel, MenuItem, FormControl, Select, CircularProgress} from '@mui/material';
import { getCart, getWishList, handleLogOut, handleClickOpen, Transition } from '../../Constants/Constant'
import { MdOutlineCancel, MdProductionQuantityLimits } from 'react-icons/md';
import { AiFillDelete,AiOutlineLogin} from 'react-icons/ai'
// import { ContextFunction } from '../Context/Context';
import { toast } from 'react-toastify';
// import { getCart, getWishList, handleLogOut, handleClickOpen, handleClose, Transition } from '../Constants/Constant'

const Collection = (props) => {

    // const { setCart } = useContext(ContextFunction)
    const [productData, setProductData] = useState([])
    const [sectionsData, setSectionsData] = useState([])
    const [loading,setLoading] = useState(false);
    const [loadingData,setLoadingData] = useState(false);
    const [productInfo, setProductInfo] = useState({
      name: "",
       });
       let authToken = localStorage.getItem('Authorization')
      //  let setProceed = authToken ? true : false
      //  let navigate = useNavigate()
    const [openAlert, setOpenAlert] = useState(false);
    const [login,setLogin] = useState(false);
    const [deleteModal,setDeleteModal]= useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [getData,setGetData] = useState(null);
    let navigate = useNavigate()
    let setProceed = authToken ? true : false
    // let navigate = useNavigate()
    useEffect(() => {
      setLoading(true)
      getAllProduct()
        window.scroll(0, 0)
    }, [])


    const getAllProduct = async () => {
 
      if (setProceed) {
          const { data } = await axios.get(`${process.env.REACT_APP_SECTIONS}/user`,

              {
                  headers: {
                      'Authorization': authToken
                  }
              })
        // console.log('-----------------4---------data',data)
        setLoading(false)
        setSectionsData(data.data);
       
      }else{
        setLogin(true)
      }

  }

  const handleToLogin = () => {
    navigate('/login')
};
    
  // const [productInfo, setCredentials] = useState({ firstName: "", lastName: '', email: "", phoneNumber: '', password: "" })
  const handleOnchange = (e) => {
      setProductInfo({ ...productInfo, [e.target.name]: e.target.value })
  }

  const handleClose = () => {
    setOpenAlert(false);
    setOpenEdit(false)
    setDeleteModal(false)
};

const Showdetail = (data) =>{
  setGetData(data)
  productInfo.name = data.name
  setOpenEdit(true)
}

const ShowDelete = (data)=>{
    setDeleteModal(true)
    setGetData(data)
}

const GoToCollection = () =>{
  navigate('/list')
}

  const getDropDown = () =>{
    props.getDropDown()
  }

  const formattedData = (data, array) => data.map((obj, i) => (  
    {
      ...obj,
      quantity: array[i]
    }
  ));

  const  moneyFormat =(price, sign = 'N') =>{
    const pieces = parseFloat(price).toFixed(2).split('')
    let ii = pieces.length - 3
    while ((ii-=3) > 0) {
      pieces.splice(ii, 0, ',')
    }
    return sign + pieces.join('')
  }
   

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoadingData(true)
    // console.log('-----------------4---------data',productInfo.name)
    try {
        if (!productInfo.name ) {
            toast.error("Please Fill the all Fields", { autoClose: 500, theme: 'colored' })
            setLoadingData(false)
        }
        else {
            const { data } = await axios.post(`${process.env.REACT_APP_SECTIONS}`,
                {
                    name: productInfo.name,
                    // owner:
                    company :'store'
                    // description: productInfo.description,
                }, {
                headers: {
                    'Authorization': authToken
                }
            })
            setOpenAlert(false);
            // console.log('-----------------4---------data',data)
            if (data.status === true) {
              toast.success("Collection created", { autoClose: 500, theme: 'colored' })
              setLoadingData(false)
              setSectionsData([...sectionsData ,data.data])
                setProductInfo({
                    name: "",
                   });
            }
            else {
              setLoadingData(false)
                toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
            }
        }
    } catch (error) {
      setLoadingData(false)
        toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
    }

}


const UpdateSubmit = async (e) =>{
  e.preventDefault()
  setLoadingData(true)
  // console.log('-----------------4---------data',productInfo.name)
  try {
      if (!productInfo.name ) {
          toast.error("Please Fill the all Fields", { autoClose: 500, theme: 'colored' })
          setLoadingData(false)
      }
      else {
          const { data } = await axios.put(`${process.env.REACT_APP_SECTIONS}/${getData._id}`,
              {
                  name: productInfo.name,
                  company :'store'
              }, {
              headers: {
                  'Authorization': authToken
              }
          })
          setOpenAlert(false);
          // console.log('-----------------4---------data',data)
          if (data.status === true) {
            toast.success("Collection Updated", { autoClose: 500, theme: 'colored' })
              setProductInfo({
                  name: "",
                 });
             setLoadingData(false)
             setOpenEdit(false)
          }
          else {
            setLoadingData(false)
              toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
          }
      }
     } catch (error) {
       setLoadingData(false)
      toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
  }
}

const deleteCollection = async ()=>{
  setLoadingData(true)
  // console.log('-----------------4---------data',getData)
     try  {
          const { data } = await axios.delete(`${process.env.REACT_APP_SECTIONS}/${getData._id}`,
               {
              headers: {
                  'Authorization': authToken
              }
          })
          // setOpenAlert(false);
          // console.log('-----------------4---------data',data)
          if (data.status === true) {
            toast.success("Collection Updated", { autoClose: 500, theme: 'colored' })
             setSectionsData(sectionsData.filter(item => item._id !== getData._id));
             setLoadingData(false)
             handleClose(false)
          }
          else {
            setLoadingData(false)
              toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
          }
     } catch (error) {
       setLoadingData(false)
      toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
  }
}


  // getDropDown
  return (
           <div>
            <div>
               <div>
                   <div className='collections-title'>
                        <button className="buttom-make collection-add" onClick={()=>setOpenAlert(!openAlert)}>
                          Add Projects
                        </button>
                         <h3>Projects</h3>
                   </div>

               {
                 sectionsData.map((item, index) => (
                  <div>
                    <div className={item.products.length  === 0 ?'collections-null':'collections'}>
                            {
                              item.products.length  === 0 ?
                                null
                              :
                                <div className='collection-show'>
                                  <Link to={`/project/${item._id}`}>
                                      <img src={item.products[0].images[0].url} />
                                    </Link>
                                </div>
                            }
                            <div className={item.products.length  === 0 ?'collection-detail-null':'collectionRight'}>
                                    
                                          <div className='collectionprice'>
                                              
                                              <br/>
                                                <p >
                                                
                                                  <a onClick={()=>ShowDelete(item)} href='#'> <AiFillDelete  color='red'/> </a>  
                                                </p>
                                          </div>
                                          
                                              <h3>  <Link to={`/project/${item._id}`}><small> {item.name}</small> </Link></h3>
                                              <h4>  </h4>
                                              <h4> </h4>
                                                {
                                                  item.products.length  === 0 ?
                                                  <button className="buttom-make collection-add-pro" onClick={()=>GoToCollection()}>
                                                    <MdOutlineAdd style={{ fontSize: 29 }}/>
                                                  </button>
                                                  :
                                                  null
                                                }
                                        
                                  
                                       {
                                         item.products.length  === 0 ?
                                          null
                                          :
                                         <div className='collection-list'>
                                          {
                                            item.products.map((item, index) => (
                                              <div className='collection-list-sub'>
                                                 <img src={item.images[0].url} />
                                              </div>
                                              ))
                                            }

                                         </div>
                                    }
                               </div>
                            </div>
                        </div>
                     ))
                 }
                    
                   
               </div>

               <Dialog
                    open={openEdit}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                  >
                  {
                    getData === null ?
                    null
                    :
                    <form onSubmit={UpdateSubmit}>
                    <DialogContent sx={{ width: { xs: 180, md: 550, xl: 200 },  justifyContent: 'center' }}>
                    <h4>Add Collections</h4>
                      <br/>
                          <div style={{width:'95%'}}>
                                
                                
                               <TextField label="Name" name='name'    value={productInfo.name}  onChange={handleOnchange} variant="outlined" fullWidth />
                                
                          </div>
                          {
                            loadingData === true?
                                <div style={{marginTop:'10%',alignItems: 'center',justifyContent: 'center',width:'20%',margin:'auto',}}>
                                     <p  style={{textAlign:'center'}} >  <CircularProgress  /> </p>
                                </div>
                              :
                            <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                              <Button fullWidth variant='contained' type='reset' color='error' onClick={handleClose} endIcon={<MdOutlineCancel />}>Cancel</Button>
                              <Button type="submit" fullWidth variant="contained" endIcon={<MdProductionQuantityLimits />}>Update</Button>
                           </DialogActions>
                          }
                    </DialogContent>
                    </form>
                  }
                  
              </Dialog>

                  <Dialog
                  open={deleteModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  aria-describedby="alert-dialog-slide-description" >
                    <DialogContent sx={{ width: { xs: 180, md: 550, xl: 200 },  justifyContent: 'center' }}>
                          <DialogContentText style={{ textAlign: 'center' }} id="alert-dialog-slide-description">
                            <Typography variant='body1'>Do you want to delete this Project?</Typography>
                        </DialogContentText>
                    
                        <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <Button variant='contained' endIcon={<AiFillDelete />} color='error' onClick={deleteCollection}>Delete</Button>
                            <Button variant='contained' color='primary'
                          onClick={() => handleClose()} endIcon={<AiFillCloseCircle />}>Close</Button>
                        </DialogActions>
                    </DialogContent>
               </Dialog>

               <Dialog
                    open={openAlert}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <form onSubmit={handleSubmit}>
                      <DialogContent sx={{ width: { xs: 180, md: 550, xl: 200 },  justifyContent: 'center' }}>
                      <h4>Add Collections</h4>
                        <br/>
                            <div style={{width:'95%'}}>
                                  
                                  
                                            <TextField label="Name" name='name'  onChange={handleOnchange} variant="outlined" fullWidth />
                                  
                            </div>
                            {
                              loadingData === true?
                                  <div style={{marginTop:'5%',alignItems: 'center',justifyContent: 'center',width:'20%',margin:'auto',}}>
                                      <p  style={{textAlign:'center'}} >  <CircularProgress  /> </p>
                                  </div>
                                :
                              <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                                <Button fullWidth variant='contained' type='reset' color='error' onClick={handleClose} endIcon={<MdOutlineCancel />}>Cancel</Button>
                                <Button type="submit" fullWidth variant="contained" endIcon={<MdProductionQuantityLimits />}>Add</Button>
                            </DialogActions>
                            }
                      </DialogContent>
                    </form>
              </Dialog>
            </div>
            <Dialog open={login}
                keepMounted
                onClose={handleClose}
                TransitionComponent={Transition}

                aria-describedby="alert-dialog-slide-description">
                <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 }, display: 'flex', justifyContent: 'center' }}>
                    <Typography variant='h5'> Please Login To Proceed</Typography>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <Button variant='contained' onClick={handleToLogin} endIcon={<AiOutlineLogin />} color='primary'>Login</Button>
                    <Button variant='contained' color='error' endIcon={<AiFillCloseCircle />} onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
          
       </div>
  )
}

export default Collection