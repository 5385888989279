import { Card, CardActionArea, CardActions, Rating, CardContent, Typography } from '@mui/material';
import { Box } from '@mui/system';
import styles from './ProductCard.module.css'

export default function ProductCard({ prod }) {


    const  moneyFormat =(price, sign = 'N') =>{
        const pieces = parseFloat(price).toFixed(2).split('')
        let ii = pieces.length - 3
        while ((ii-=3) > 0) {
          pieces.splice(ii, 0, ',')
        }
        return sign + pieces.join('')
      }
    
      
    return (
        <Card className={styles.main_card}>
           
            {
                prod.images === undefined ?
                <CardActionArea className={styles.card_action}>
                
                  <Box className={styles.cart_box}>
                        <img alt={prod.productId.name} src={prod.productId.images[0].url} loading='lazy' className={styles.cart_img} />
                    </Box>
                </CardActionArea>
                :
                <CardActionArea className={styles.card_action}>
                        <Box className={styles.cart_box}>
                            <img alt={prod.name} src={prod.images[0].url} loading='lazy' className={styles.cart_img} />
                        </Box>
             </CardActionArea>
                    
            }
     
           
            <CardActions style={{ display: "flex", justifyContent: "space-between", width: '100%' }}>
            {
                prod.images === undefined ?
                <Typography variant="h6" color="primary">
                     {moneyFormat(prod.productId.price)}
                     {
                        console.log('-------------prod------------data',prod)  
                    }
                </Typography>
                :
                <Typography variant="h6" color="primary">
                    {moneyFormat(prod.price)}
                    
            </Typography>

            }
              
                {
                    prod.rating === undefined ?
                    <Typography >
                    <Rating precision={0.5} name="read-only" value={prod.productId.rating} size="small" readOnly />
                    </Typography>
                    :
                    <Typography >
                    <Rating precision={0.5} name="read-only" value={prod.rating} size="small" readOnly />
                    </Typography>
                }
            


            </CardActions>
        </Card >
    );
}