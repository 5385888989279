import './Productsimilar.css'
import React, { useEffect, useState, useContext } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import {
    Box,
    Button,
    Container,
    Tooltip,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Chip,
    Rating,
    ButtonGroup,
    Grid,
    Skeleton,
    IconButton,
} from '@mui/material';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { MdAddShoppingCart } from 'react-icons/md'
import { AiFillHeart, AiFillCloseCircle, AiOutlineLogin, AiOutlineShareAlt } from 'react-icons/ai'
import { TbDiscount2 } from 'react-icons/tb'
import axios from 'axios';
import { toast } from 'react-toastify';
import ImageGallery from 'react-image-gallery';
import { Helmet } from "react-helmet";
import { ContextFunction } from '../../Context/Context';
import ProductReview from '../../Components/Review/ProductReview';
import {
    FacebookShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    TelegramShareButton,
    TelegramIcon,
    LinkedinShareButton,
    LinkedinIcon,
    PinterestShareButton,
    PinterestIcon,
    EmailShareButton,
    EmailIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon
    
  } from 'react-share';
import ProductCard from '../../Components/Card/Product Card/ProductCard';
import { Transition, getSingleProduct } from '../../Constants/Constant';
// import Slider from "react-slick";
import CopyRight from '../../Components/CopyRight/CopyRight';

const images = [
    {
      original: 'http://lorempixel.com/1000/600/nature/1/',
      thumbnail: 'http://lorempixel.com/250/150/nature/1/',
    },
    {
      original: 'http://lorempixel.com/1000/600/nature/2/',
      thumbnail: 'http://lorempixel.com/250/150/nature/2/'
    },
    {
      original: 'http://lorempixel.com/1000/600/nature/3/',
      thumbnail: 'http://lorempixel.com/250/150/nature/3/'
    }
  ]

const ProductDetail = () => {
    const { cart, setCart, wishlistData, setWishlistData } = useContext(ContextFunction)
    const [openAlert, setOpenAlert] = useState(false);
    const { id, cat } = useParams()
    const [product, setProduct] = useState([])
    const [images, setImages]= useState([])
    const [similarProduct, setSimilarProduct] = useState([])
    const [productQuantity, setProductQuantity] = useState(1)
    const [loading, setLoading] = useState(true);
    const [loginOpen,setLoginOpen]= useState(false);
    const [shareMoald,setShareMoald] = useState(false);
    const [buyData,setBuyData] = useState(false);
    const [processData,setProcessData] = useState(null)
    let navigate = useNavigate()

    let authToken = localStorage.getItem('Authorization')
    let setProceed = authToken ? true : false
    const shareUrl = 'https://www.store.ufuon.com';

    useEffect(() => {

        getSingleProduct(setProduct, id, setLoading)
        // getSingleIamge(setImages, id,setLoading)
        getSimilarProducts()
          const ArrayDATA = []
            const requestOptions = {
              method: 'get',
              headers: { 'Content-Type': 'application/json' },
              // body: JSON.stringify(login),
            };
            fetch(`${process.env.REACT_APP_FETCH_PRODUCT}/${id}`,requestOptions)
            .then(res => res.json())
            .then(
              (res) => {
                // setAssignmentAll(res.data)
                // console.log("data ----true-judge-articl--------------------e----->",res)
                for (let i = 0; i < res.images.length; i++) {
                            // console.log('data----------------->',res.images[i].url)
                                 const newData ={
                                        original:  res.images[i].url,
                                        thumbnail:  res.images[i].url,
                                 }
                                //  setImages([...images, newData])
                          ArrayDATA.push(newData)
                     }
                     setImages(ArrayDATA)
                  },
                (error) => {

              })
        
        window.scroll(0, 0)
        // if(loading === false){
            // getAllImage(product)
        // }
        // 
    }, [id])


    const addToCart = async (product) => {
        if (setProceed) {
            try {
                const { data } = await axios.post(`${process.env.REACT_APP_ADD_CART}`, { _id: product._id, quantity: productQuantity }, {
                    headers: {
                        'Authorization': authToken
                    }
                })
                setCart(data)
              
                setCart([...cart, product])
                toast.success("Added To Cart", { autoClose: 500, theme: 'colored' })
            } catch (error) {
                toast.error(error.response.data.msg, { autoClose: 500, theme: 'colored' })
            }
        }
        else {
            setOpenAlert(true);
        }
    }
    const addToWhishList = async (product) => {
        if (setProceed) {
            try {
                const { data } = await axios.post(`${process.env.REACT_APP_ADD_WISHLIST}`, { _id: product._id }, {
                    headers: {
                        'Authorization': authToken
                    }
                })
                setWishlistData(data)
                setWishlistData([...wishlistData, product])
                toast.success("Added To Wishlist", { autoClose: 500, theme: 'colored' })
            }
            catch (error) {
                toast.error(error.response.data.msg, { autoClose: 500, theme: 'colored' })
            }
        }
        else {
            setOpenAlert(true);
        }

    };
    const shareProduct = (product) => {
        setShareMoald(true)
        // const data = {
        //     text: product.name,
        //     title: "e-shopit",
        //     url: `https://e-shopit.vercel.app/Detail/type/${cat}/${id}`
        // }
        // if (navigator.canShare && navigator.canShare(data)) {
        //     navigator.share(data);
        // }
        // else {
        //     toast.error("browser not support", { autoClose: 500, theme: 'colored' })
        // }

    }

    const getAllImage =(data)=>{
    //  console.log("product---------->",product    )

        if(loading === false){

            // console.log("product-----2-----------=====----->",data.images     )
        //  for (let i = 0; i < product.images.length; i++) {
        //      const newData ={
        //             original:  product.images[i].url,
        //             thumbnail:  product.images[i].url,
        //      }
        //       setImages([...images,  newData])
        //   }
         }

    
    }
    const getSimilarProducts = async () => {
        const { data } = await axios.post(`${process.env.REACT_APP_PRODUCT_TYPE}`, { userType: cat.toLowerCase() })
        setSimilarProduct(data)
    }
    let data = [];
    if (cat === 'phone') {
        data.push(product?.brand, product?.gender, product?.category)
    }
    else if (cat === 'robotics') {
        data.push(product.author, product.category)
    }
    else if (cat === 'computers') {
        data.push(product.category, cat)
    }
    else if (cat === 'tablet') {
        data.push(product.category, cat)
    }
    else if (cat === 'electronic') {
        data.push(product.category, cat)
    }
    else if (cat === 'book') {
        data.push(product.category, cat)
    }
    else if (cat === 'laboratory') {
        data.push(cat)
    }
    const increaseQuantity = () => {
        setProductQuantity((prev) => prev + 1)
        if (productQuantity >= 5) {
            setProductQuantity(5)
        }
    }
    const decreaseQuantity = () => {
        setProductQuantity((prev) => prev - 1)
        if (productQuantity <= 1) {
            setProductQuantity(1)
        }
    }


    const  moneyFormat =(price, sign = 'N') =>{
        const pieces = parseFloat(price).toFixed(2).split('')
        let ii = pieces.length - 3
        while ((ii-=3) > 0) {
          pieces.splice(ii, 0, ',')
        }
        return sign + pieces.join('')
      }
    
      const handleClose = ()=>{
        setBuyData(false)
        setShareMoald(false)
      }

      const ProcessToCheckOut = async () =>{
        if (setProceed) {
            try {
                const { data } = await axios.post(`${process.env.REACT_APP_ADD_CART}`, { _id: product._id, quantity: productQuantity }, {
                    headers: {
                        'Authorization': authToken
                    }
                })
                setCart(data)
              
                setCart([...cart, product])
                navigate('/cart')
                toast.success("Added To Cart", { autoClose: 500, theme: 'colored' })
            } catch (error) {
                toast.error(error.response.data.msg, { autoClose: 500, theme: 'colored' })
            }
        }
        else {
            setOpenAlert(true);
        }
      }

    const BuyNOW = (data)=>{
   
        if (authToken !== null) {
            setProcessData(data)
            setBuyData(true)
          }else{
            setLoginOpen(true)
          }

    }

      const continueToShop = ()=>{
        navigate('/')
      }
   
      function ToSeoUrl(url) {
        
        // make the url lowercase         
        var encodedUrl = url.toString().toLowerCase(); 
      
        // replace & with and           
        encodedUrl = encodedUrl.split(/\&+/).join("-and-")
      
        // remove invalid characters 
        encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");       
      
        // remove duplicates 
        encodedUrl = encodedUrl.split(/-+/).join("-");
      
        // trim leading & trailing characters 
        encodedUrl = encodedUrl.trim('-'); 
      
        return encodedUrl; 
      }

      const handleToLogin = () => {
        navigate('/login')
      };
      

      
    return (
        <>
        
            <Container maxWidth='xl' className='ProductDetalt-Body' >
            {
                // console.log('-----------product',product === undefined ? null : product.images[0].url)
            }
            {
                product === null || product === undefined?
                null
                :
               <Helmet
                  title={product.name}
                  meta={[
                  { name: product.name, content: shareUrl },
    
                  { name: 'twitter:site', content: product.name },
                  { name: 'twitter:creator', content:product.name },
                  { name: 'twitter:title', content: product.name },
                  { name: 'twitter:image', content: product.name },
    
                  { property: 'og:title', content: product.name },
                  { property: 'og:site_name', content: product.name === null || product.name === undefined? null: `${shareUrl}/Detail/type/${product.type}/${ToSeoUrl(product.name)}/${product._id}`},
                  { property: 'og:type', content: shareUrl },
                  { property: 'og:url', content: product.name === null || product.name === undefined? null :`${shareUrl}/Detail/type/${product.type}/${ToSeoUrl(product.name)}/${product._id}`},
                  { property: 'og:description', content:product.description},
                  { property: 'og:image', content:product.images === null || product.images === undefined? null :product.images[0].url},
                  { property: 'og:site_name',content: shareUrl },
    
                  { name: 'viewport', content: 'width=device-width, maximum-scale=1' },
                  { name: 'apple-itunes-app', content: 'app-id=1125423676' },
                  ]}
                  
                  />
                  
                }

                <Dialog
                    open={openAlert}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenAlert(false)}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 } }}>
                        <DialogContentText style={{ textAlign: 'center' }} id="alert-dialog-slide-description">
                            Please Login To Proceed
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Link to="/login"> <Button variant='contained' endIcon={<AiOutlineLogin />} color='primary'>Login</Button></Link>
                        <Button variant='contained' color='error'
                            onClick={() => setOpenAlert(false)} endIcon={<AiFillCloseCircle />}>Close</Button>
                    </DialogActions>
                </Dialog>

             
                <div className='product-details-sub'>
                 
                   <div className='product-details-slide'>
                        {loading ? (
                            <Skeleton variant='rectangular' height={400} />
                        ) : (
                            <div className="product-image">
                            
                                <ImageGallery items={images} />
                            </div>
                        )}
                   </div>

                   <div className='product-details-title'>
                   {loading ? (
                    <section style={{ display: 'flex', flexWrap: "wrap", width: "100%", justifyContent: "space-around", alignItems: 'center' }}>
                        <Skeleton variant='rectangular' height={200} width="200px" />
                        <Skeleton variant='text' height={400} width={700} />

                    </section>

                        ) : (
                            <section className='product-details'>
                                <Typography variant='h6'>{product.name}</Typography>

                                <Typography >
                                Categories : {product.type}
                                </Typography>
                                <Typography >
                                    <div className="chip">
                                        {
                                            data.map((item, index) => (
                                                <Chip label={item} key={index} variant="outlined" />
                                            ))
                                        }
                                    </div>
                                </Typography>
                                <Chip
                                    label={product.price > 1000 ? "Upto 9% off" : "Upto 38% off"}
                                    variant="outlined"
                                    sx={{ background: '#1976d2', color: 'white', width: '150px', fontWeight: 'bold' }}
                                    avatar={<TbDiscount2 color='white' />}


                                />
                                <div style={{ display: 'flex', gap: 20 }}>
                                    <Typography variant="h6" color="red"><s>  {moneyFormat(product.price > 1000 ? product.price + 1000 : product.price + 300)}</s> </Typography>
                                    <Typography variant="h6" color="primary">
                                       {moneyFormat(product.price)}
                                    </Typography>
                                </div>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        // background: 'red',
                                        '& > *': {
                                            m: 1,
                                        },
                                    }} >
                                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                                        <Button onClick={increaseQuantity}>+</Button>
                                        <Button>{productQuantity}</Button>
                                        <Button onClick={decreaseQuantity}>-</Button>
                                    </ButtonGroup>
                                </Box>
                                <Rating name="read-only" value={Math.round(product.rating)} readOnly precision={0.5} />
                                <div style={{ display: 'flex' }} >
                                    <Tooltip title='Add To Cart'>
                                        <Button variant='contained' className='all-btn' startIcon={<MdAddShoppingCart />} onClick={(() => BuyNOW(product))}>Buy</Button>
                                    </Tooltip>
                                    <Tooltip title='Add To Wishlist'>
                                        <Button style={{ marginLeft: 10, }} size='small' variant='contained' className='all-btn' onClick={(() => addToWhishList(product))}>
                                            {<AiFillHeart fontSize={21}/>}
                                        </Button>

                                    </Tooltip>
                                    <Tooltip title='Share'>
                                        <Button style={{ marginLeft: 10 }} variant='contained' className='all-btn' startIcon={<AiOutlineShareAlt />} onClick={() => shareProduct(product)}>Share</Button>
                                    </Tooltip>

                                </div>
                            </section>
                        )}
                   </div>
                </div>

                
                {loading ? (
                    <section style={{ display: 'flex', flexWrap: "wrap", width: "100%", justifyContent: "space-around", alignItems: 'center' }}>
                        <Skeleton variant='rectangular' height={200} width="200px" />
                        <Skeleton variant='text' height={400} width={700} />

                    </section>

                   ) : (
                       
                            <div className='ProductDetalt-dis'>
                              <h3 style={{marginBottom:'0.5%'}}>Product details</h3>
                              <hr style={{background:'rgba(0,0,0,.05)'}}/>
                            <Typography >
                                 <MarkdownPreview source={product.description} />
                        
                            </Typography>
                            </div>
                
                    
                    )
                }
  
                <Dialog
                open={shareMoald}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"  >
              
                <DialogContent sx={{ width: { xs: 180, md: 550, xl: 200 },  justifyContent: 'center' }}>
                <h3>Share Projects</h3>
                  {loading ? (
                    <section style={{ display: 'flex', flexWrap: "wrap", width: "100%", justifyContent: "space-around", alignItems: 'center' }}>
                        <Skeleton variant='rectangular' height={200} width="200px" />
                        <Skeleton variant='text' height={400} width={700} />

                    </section>

                   ) : (
                      <div className='collectDetail-share'>
                      {
                        product === null || product === undefined?
                        null
                        :
                            <ul>
                              <li>
                                  <FacebookShareButton
                                    url={ `${shareUrl}/Detail/type/${product.type}/${ToSeoUrl(product.name)}/${product._id}`}
                                    quote={product.name  +' ' + product.description }
                                    hashtag={product.name }  >
                                    <FacebookIcon size={40} round={true} />
                                  </FacebookShareButton>
                              </li>
                              <li>
                                  <WhatsappShareButton
                                  url={ `${shareUrl}/Detail/type/${product.type}/${ToSeoUrl(product.name)}/${product._id}`}
                                  quote={product.name  +' ' + product.description }
                                  hashtag={product.name } >
                                    <WhatsappIcon size={40} round={true} />
                                </WhatsappShareButton>
                              </li>
                              <li>
                                  <TwitterShareButton
                                  url={ `${shareUrl}/Detail/type/${product.type}/${ToSeoUrl(product.name)}/${product._id}`}
                                    quote={product.name  +' ' + product.description }
                                    title={product.name  +' ' + product.description }
                                    via={product.name }
                                    hashtag={product.name } >
                                    <TwitterIcon size={40} round={true} />
                                </TwitterShareButton>
                              </li>
                              <li>
                                <TelegramShareButton
                                url={ `${shareUrl}/Detail/type/${product.type}/${ToSeoUrl(product.name)}/${product._id}`}
                                    quote={product.name  +' ' + product.description }
                                    hashtag={product.name }>
                                <TelegramIcon size={40} round={true} />
                                </TelegramShareButton>
                              </li>
                              <li>
                                <LinkedinShareButton
                                url={ `${shareUrl}/Detail/type/${product.type}/${ToSeoUrl(product.name)}/${product._id}`}
                                    quote={product.name  +' ' + product.description }
                                    summary={product.name  +' ' + product.description }
                                    hashtag={product.name }>
                                <LinkedinIcon size={40} round={true} />
                                </LinkedinShareButton>
                              </li>

                              <li>
                                <PinterestShareButton
                                url={ `${shareUrl}/Detail/type/${product.type}/${ToSeoUrl(product.name)}/${product._id}`}
                                    quote={product.name  +' ' + product.description }
                                    hashtag={product.name }>
                                <PinterestIcon size={40} round={true} />
                                </PinterestShareButton>
                              </li>
                              <li>
                                <EmailShareButton
                                url={`${shareUrl}/Detail/type/${product.type}/${ToSeoUrl(product.name)}/${product._id}`}
                                    quote={product.name  +' ' + product.description }
                                    hashtag={product.name }>
                                <EmailIcon size={40} round={true} />
                                </EmailShareButton>
                              </li>
                              <li>
                                <FacebookMessengerShareButton
                                url={ `${shareUrl}/Detail/type/${product.type}/${ToSeoUrl(product.name)}/${product._id}`}
                                    quote={product.name  +' ' + product.description }
                                    hashtag={product.name }>
                                <FacebookMessengerIcon size={40} round={true} />
                                </FacebookMessengerShareButton>
                              </li>
                          </ul>
                      }
                      </div>
                   )
                    }
                  </DialogContent>
            </Dialog>

            <Dialog
            open={loginOpen}
            TransitionComponent={Transition}
            keepMounted
              onClose={() => handleClose(setOpenAlert)}
              aria-describedby="alert-dialog-slide-description"
              >
                <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 }, display: 'flex', justifyContent: 'center' }}>
                    <Typography variant='h5'> Please Login To Proceed</Typography>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <Button variant='contained' onClick={handleToLogin} endIcon={<AiOutlineLogin />} color='primary'>Login</Button>
                    <Button variant='contained' color='error' endIcon={<AiFillCloseCircle />} onClick={handleClose}>Close</Button>
                </DialogActions>
          </Dialog>

                <Dialog open={buyData}
                        keepMounted
                        onClose={handleClose}
                        TransitionComponent={Transition}
            
                        aria-describedby="alert-dialog-slide-description">
                        <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 }, display: 'flex', justifyContent: 'center' }}>
                            <Typography variant='h5'>Clcik To Proceed</Typography>

                        </DialogContent>
                        <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Button variant='contained'  onClick={(() => addToCart(product))} endIcon={<MdAddShoppingCart />} color='primary'> add To Cart</Button>
                        <Button variant='contained' color='error' endIcon={<MdAddShoppingCart/>} onClick={()=>ProcessToCheckOut()}>Buy Now</Button>
                        </DialogActions>
                        <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <Button variant='contained' onClick={()=>continueToShop()} endIcon={<AiOutlineLogin />} color='primary'>Continue To Shop</Button>
                            
                        </DialogActions>
                    </Dialog>
                
                <ProductReview setProceed={setProceed} authToken={authToken} id={id} setOpenAlert={setOpenAlert} />



            </Container >
            <CopyRight   sx={{ mt: 8, mb: 10 }} />

        </>
    )
}

export default ProductDetail