import { Button, Card, CardActionArea, CardActions, CardContent, Rating, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Link } from 'react-router-dom'
import { AiFillDelete } from 'react-icons/ai'
import styles from './CartCard.module.css'
const UpdateCartCard = ({ product, removeFromCart }) => {

    const  moneyFormat =(price, sign = 'N') =>{
        const pieces = parseFloat(price).toFixed(2).split('')
        let ii = pieces.length - 3
        while ((ii-=3) > 0) {
          pieces.splice(ii, 0, ',')
        }
        return sign + pieces.join('')
      }


    function ToSeoUrl(url) {
        
        // make the url lowercase         
        var encodedUrl = url.toString().toLowerCase(); 
      
        // replace & with and           
        encodedUrl = encodedUrl.split(/\&+/).join("-and-")
      
        // remove invalid characters 
        encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");       
      
        // remove duplicates 
        encodedUrl = encodedUrl.split(/-+/).join("-");
      
        // trim leading & trailing characters 
        encodedUrl = encodedUrl.trim('-'); 
      
        return encodedUrl; 
      }
      
      
    return (
        <div className='Cart-product-card'>
            <Link to={`/Detail/type/${product?.productId?.type}/${ToSeoUrl(product?.productId?.name)}/${product?.productId?._id}`} >
            <CardActionArea >
                <div className='Homepage-product-img'>
                        <img alt={product?.productId?.name} loading='lazy' src={product?.productId?.images[0].url} className={styles.img} />
                  </div>
                <CardContent>
                    <p style={{marginBottom:'5px'}}>
                        {product?.productId?.name.length > 20 ? product?.productId?.name.slice(0, 20) + '...' : product?.productId?.name}
                    </p>
                 
                    <p style={{float:'right'}}>
                    {moneyFormat(product?.productId?.price)}
                    </p>
                        {product.quantity &&   <p style={{textAlign:'left'}}>Quantity {' ' + product.quantity} </p>}
                      
                 
                </CardContent>
            </CardActionArea>
            </Link>
            <div style={{ display: "flex", justifyContent: "space-between", width: '100%' }}>
                <Tooltip
                    title='Remove From Cart'>
                    <Button className='all-btn' sx={{ width: 10, borderRadius: '30px' }} variant='contained' color='error' onClick={() => removeFromCart(product)}>
                        <AiFillDelete style={{ fontSize: 15 }} />
                    </Button>
                </Tooltip>
                <Typography> <Rating name="read-only" size={'small'} value={Math.round(product?.productId?.rating)} readOnly  precision={0.5}/></Typography>
            </div>
        </div>
    )
}

export default UpdateCartCard