import { Card, CardActionArea, CardActions, Rating, CardContent, Typography } from '@mui/material';
import { Box } from '@mui/system';
// import styles from './ProductCard.module.css'
import { Link, useParams } from 'react-router-dom'
import {MdOutlineAdd,MdDeleteOutline } from 'react-icons/md'
import {GrAdd} from 'react-icons/gr'


export default function UpdateProduct({ prod ,to ,sendData}) {


    const  moneyFormat =(price, sign = 'N') =>{
        const pieces = parseFloat(price).toFixed(2).split('')
        let ii = pieces.length - 3
        while ((ii-=3) > 0) {
          pieces.splice(ii, 0, ',')
        }
        return sign + pieces.join('')

        
      }
    
    //  const CallAddData = (data) =>{
    //      sendData(data)
    //  }
    
      
    return (
        <div className='HomePage-product-card'>
        <Link to={to}>
            <CardActionArea >
            <div className='Homepage-product-img'>
                    <img alt={prod.name} src={prod.images[0].url} loading='lazy'  />
              </div>
        
            </CardActionArea>
            </Link>   
            <div className='Homepage-product-details'>
                <Link to={to}>
                <p>
                    {prod.name.length > 35 ? prod.name.slice(0, 35) + '...' : prod.name}
                </p>
                </Link>
                <CardActions style={{ display: "flex", justifyContent: "space-between", width: '100%' }}>
                <Link to={to}>
                    <p  style={{fontSize:'13px',position:'relative',right:'10px',bottom:'3px',color:'#1976d2'}}>
                        {moneyFormat(prod.price)}
                    </p>
                    </Link>
                    <Typography >
                      <Rating precision={0.5} name="read-only" value={prod.rating} size="small" readOnly />
                    </Typography>
                </CardActions>
            </div>
            
       </div>
    );
}